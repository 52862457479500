import React from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

// Register Chart.js modules
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const HorizontalBarChart = () => {
  // Hardcoded data
  const winPercentage = 58;
  const lossPercentage = 37;
  const drawPercentage = 5;

  // Data for the horizontal bar
  const data = {
    labels: [""], // Single bar, so one label
    datasets: [
      {
        label: "Win",
        data: [winPercentage],
        backgroundColor: "#4caf50", // Green for wins
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
      {
        label: "Loss",
        data: [lossPercentage],
        backgroundColor: "#f44336", // Red for losses
      },
      {
        label: "Draw",
        data: [drawPercentage],
        backgroundColor: "#ff9800", // Orange for draws
      },
    ],
  };

  // Options for the chart
  const options = {
    indexAxis: "y", // Horizontal bar chart
    responsive: true,
    scales: {
      x: {
        stacked: true, // Stack bars together
        ticks: {
          callback: (value) => `${value}%`, // Add '%' to x-axis labels
        },
      },
      y: {
        stacked: true, // Single bar
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}%`;
          },
        },
      },
      legend: {
        position: "top", // Legend at the top
      },
    },
  };

  return (
    <div style={{ width: "80%", margin: "20px auto" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default HorizontalBarChart;

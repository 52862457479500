import React, { useEffect, useState } from "react";
import { useWebSocket } from "../contexts/WebSocketContext";

const Modal = ({ isOpen, onClose, invitation, setInvitations }) => {
  console.log('freind modal', invitation);

  if (!isOpen) return null;

  const handleDeleteInvitation = async (invitationId) => {
    try {
      setInvitations((prevList) =>
        prevList.filter((invitation) => invitation.invitationId !== invitationId)
      );
      console.log('im trying to delete');

      const token = localStorage.getItem("token");
      const response = await fetch("https://grandhustler.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
            mutation RejectInvitation($invitationId: ID!) {
              rejectInvitation(invitationId: $invitationId) {
                success
                message
              }
            }
          `,
          variables: { invitationId },
        }),
      });

      const result = await response.json();

      if (!result.data.rejectInvitation.success) {
        console.error("Error rejecting invitation:", result.data.rejectInvitation.message);
      }
    } catch (error) {
      console.error("Error rejecting invitation:", error);
    }
  };

  const handleAcceptInvitation = async (invitationId, wager) => {
    try {
      const wagerFloat = parseFloat(wager);
      const hasSufficientFunds = await checkFunds(wagerFloat);

      if (hasSufficientFunds) {
        await putMoneyInPot(wagerFloat);

        const token = localStorage.getItem("token");
        const response = await fetch("https://grandhustler.com/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: `
              mutation AcceptInvitation($invitationId: ID!) {
                acceptInvitation(invitationId: $invitationId) {
                  success
                  message
                  roomId
                  color
                }
              }
            `,
            variables: { invitationId },
          }),
        });

        const result = await response.json();
        if (!result.data.acceptInvitation.success) {
          console.error("Error accepting invitation:", result.data.acceptInvitation.message);
        }
      } else {
        console.log("Insufficient funds");
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
    }
  };

  const checkFunds = async (cleanedWager) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("https://grandhustler.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
            query SufficientFunds($wager: Float!) {
              sufficientFunds(wager: $wager)
            }
          `,
          variables: { wager: cleanedWager },
        }),
        credentials: "include",
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      return result.data.sufficientFunds;
    } catch (error) {
      console.error("Error checking funds:", error);
      return false;
    }
  };

  const putMoneyInPot = async (cleanedWager) => {
    try {
      const token = localStorage.getItem("token");
      const freeCashPrize = false;

      if (!token) {
        throw new Error("No token found in localStorage");
      }

      const response = await fetch("https://grandhustler.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
            mutation PutMoneyInPot($wager: Float!, $join: Boolean!) {
              putMoneyInPot(wager: $wager, join: $join)
            }
          `,
          variables: {
            wager: cleanedWager,
            join: freeCashPrize,
          },
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
      }

      const data = await response.json();
      if (data.errors) {
        throw new Error(`GraphQL error: ${data.errors.map((err) => err.message).join(", ")}`);
      }

      return data;
    } catch (error) {
      console.error("Error putting money in pot:", error);
    }
  };
  

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div
        style={{
          background: "white",
          borderRadius: "8px",
          padding: "1rem",
          minWidth: "300px",
          maxWidth: "500px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ marginBottom: '2rem' }}>
          <div style={{ fontSize: '30px', fontWeight: '700', color: 'rgb(60, 60, 60)' }}>
            Invite 
          </div>
          <div>{invitation?.senderUsername}</div>
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <span style={{ fontSize: '14px', fontWeight: 700}}>Variation</span>
            <span style={{ fontSize: '12px' }}>{invitation?.variation}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <span style={{ fontSize: '14px', fontWeight: 700}}>Wager</span>
            <span style={{ fontSize: '12px' }}>{invitation?.wager}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <span style={{ fontSize: '14px', fontWeight: 700}}>Time</span>
            <span style={{ fontSize: '12px' }}>{invitation?.time}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <span style={{ fontSize: '14px', fontWeight: 700}}>Your Color</span>
            <span style={{ fontSize: '12px' }}>{invitation?.color}</span>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            className="reject"
            onClick={() => handleDeleteInvitation(invitation.invitationId)}
          >
            <svg
              viewBox="0 0 24 24"
              width="20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#0F1729"
              ></path>
            </svg>
          </button>

          <button
            className="accept"
            onClick={() => handleAcceptInvitation(invitation.invitationId, invitation.wager)}
          >
            <div className="chessboard"></div>
          </button>
        </div>
      </div>
    </div>
  );
};

const SkeletonLoader = () => (
  <div className="skeleton-loader">
    <div className="skeleton-avatar"></div>
    <div className="skeleton-text"></div>
  </div>
);

const OnlineFriend = () => {
  const [onlineFriends, setOnlineFriends] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [invitations, setInvitations] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalFriend, setModalFriend] = useState(null);
  const [loading, setLoading] = useState(true); 
  const { socket } = useWebSocket();

  const fetchMutualFriends = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Authorization token not found");

      const query = `
        query {
          getMutualFriends {
            id
            username
            profilePic
          }
        }
      `;

      const response = await fetch("https://grandhustler.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.errors?.[0]?.message || "Failed to fetch mutual friends");
      }

      setOnlineFriends(data.data.getMutualFriends);
    } catch (error) {
      console.error("Error fetching mutual friends:", error.message);
    } finally {
      setLoading(false); // Stop loading after fetching data
    }
  };

  useEffect(() => {
    fetchMutualFriends();
  }, []);

  const handleNewInvitation = (invitation) => {
    setInvitations((prevInvitations) => {
      const existingInvitation = prevInvitations.find(
        (inv) => inv.invitationId === invitation.invitationId
      );

      if (existingInvitation) {
        return prevInvitations.map((inv) =>
          inv.invitationId === invitation.invitationId ? invitation : inv
        );
      }
      return [...prevInvitations, invitation];
    });
  };

  useEffect(() => {
    if (socket) {
      socket.on("send_invitation", handleNewInvitation);
      socket.on("new_invitation", handleNewInvitation);
    }

    return () => {
      if (socket) {
        socket.off("send_invitation", handleNewInvitation);
        socket.off("new_invitation", handleNewInvitation);
      }
    };
  }, [socket]);

  const handleFriendClick = (friendId) => {
    setSelectedFriend((prev) => (prev === friendId ? null : friendId));
  };

  const getInvitationCount = (username) => {
    console.log('invitationUSername', username);
    return invitations.filter((inv) => inv.senderUsername === username).length;
  };

  const getInvitationByUsername = (username) => {
    console.log('Searching for invitation from username:', username);
    return invitations.find((inv) => inv.senderUsername === username);
  };

  const openModal = (invitation) => {
    setSelectedInvitation(invitation);
    setModalOpen(true);
  };
  
  const closeModal = () => {
    setModalOpen(false);
    setSelectedInvitation(null);
  };
  

  return (
    <div className="w-100">
      <div className="menu-group news-section flex fd-c gap-16rem">
        <div className="group-title flex fg-0">
          <span className="icon-title" style={{ display: "inline" }}>
            Friends
          </span>
        </div>
        {loading
          ? Array.from({ length: 5 }).map((_, index) => (
              <SkeletonLoader key={index} />
            ))
          : 
        onlineFriends.length > 0 ? (
          onlineFriends.map((friend) => {
            const invitationCount = getInvitationCount(friend.username);

            return (
              <div key={friend.id}>
                <div
                  className="friend"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFriendClick(friend.id)}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <div className="friend-profile-pic">
                      <img
                        src={
                          friend.profilePic ||
                          "https://www.chess.com/bundles/web/images/user-image.007dad08.svg"
                        }
                        height="20px"
                        alt={friend.username}
                        width="20px"
                        style={{
                          borderRadius: "4px",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                    <div className="info" style={{ display: 'flex', alignItems: 'center'}}>
                      <div className="username">{friend.username}</div>
                    
                    {invitationCount > 0 && (
                      <div
                        style={{
                          background: "green",
                          color: "white",
                          borderRadius: "50%",
                          width: "12px",
                          height: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          marginLeft: '4px'
                        }}
                      >
                        {invitationCount}
                      </div>
                    )}
                    </div>
                  </div>
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                  >
                    <path
                      d="M6 9L12 15L18 9"
                      stroke="#FFFFFF1a"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                {selectedFriend === friend.id && (
  <>
    {invitationCount > 0 && (
      <div style={{ padding: "0 0.5rem 0 3rem" }}>
        <div
          style={{
            background: "rgb(40, 167, 69)",
            color: "white",
            padding: "4px 8px",
            marginTop: "0.5rem",
            borderRadius: "4px",
            textAlign: "center",
            transition: "max-height 0.3s ease",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "700",
          }}
          onClick={() => {
            const matchingInvitation = getInvitationByUsername(friend.username);
            if (matchingInvitation) {
              openModal(matchingInvitation); // Pass the invitation to the modal
            } else {
              console.log("No matching invitation found");
            }
          }}
        >
          Invite
        </div>
      </div>
    )}
    <div style={{ padding: "0 0.5rem 0 3rem" }}>
      <div
        style={{
          background: "rgb(0,149,246)",
          color: "white",
          padding: "4px 8px",
          marginTop: "0.5rem",
          borderRadius: "4px",
          textAlign: "center",
          transition: "max-height 0.3s ease",
          cursor: "pointer",
          fontSize: "12px",
          fontWeight: "700",
        }}
        onClick={() => console.log(`Create game with ${friend.username}`)}
      >
        Create Game
      </div>
    </div>
  </>
)}

              </div>
            );
          })
        ) : (
          <div className="center p2" style={{ color: '#fff' }}>You have no friends lol.</div>
        )}
      </div>

      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        invitation={selectedInvitation} 
        setInvitations={setInvitations}
      />
    </div>
  );
};

export default OnlineFriend;

import React, { useState, useEffect } from 'react';
import Dropdown from './DropdownOptions'; 
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import TournamentConfirmation from './ConfirmTourneyCreation'; // Import the modal component
import DatePicker from "react-datepicker";

const getTimeNow = (setHour, setMinute, setDate) => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const currentHour = today.getHours();
  const currentMinute = today.getMinutes();

  const formattedDate = month.toString().padStart(2, '0') + '/' + day.toString().padStart(2, '0');
  const formattedMinute = currentMinute.toString().padStart(2, '0');
  const nextHour = (currentHour + 1).toString().padStart(2, '0');

  setHour(nextHour);
  setMinute(formattedMinute);
  setDate(formattedDate);
};

const TournamentForm = () => {
  const [variation, setVariation] = useState('Standard');
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [date, setDate] = useState('');
  const [size, setSize] = useState('');
  const [mode, setMode] = useState('');
  const [wager, setWager] = useState('');
  const [minElo, setMinElo] = useState('-∞');
  const [maxElo, setMaxElo] = useState('+∞');
  const [tournamentDate, setTournamentDate] = useState(new Date()); // Initial date state
  const [tourneyName, setTourneyName] = useState('');
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [freeCashPrize, setFreeCashPrize] = useState(false); // State to toggle wager input
  const [currentSlide, setCurrentSlide] = useState(0);

  const variationOptions = ['Standard', 'King of the Hill'];
  const hourOptions = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minuteOptions = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  const sizeOptions = ['4-Man', '8-Man', 'No Limit'];
  const modeOptions = ['1 Min', '2 | 1', '3 Min', '3 | 2', '5 Min', '10 Min', '30 Min'];
  const wagerOptions = ['0', '10,000', '50,000', '100,000', '200,000'];
  const minEloOptions = ['-∞', '-400', '-200', '-150', '-100', '-50', '-25'];
  const maxEloOptions = ['+∞', '+400', '+200', '+150', '+100', '+50', '+25'];

  useEffect(() => {
    getTimeNow(setHour, setMinute, setDate);
  }, []);

  const handleNextClick = async () => {
    if (currentSlide < slides.length - 1) {
      //take to next slide
      setCurrentSlide(currentSlide + 1)
    } else {
      //invite
      await handleSubmit();
    }
  };

  const handleBackClick = () => {
    if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
  };

  const handleDateChange = (selectedDate) => {
    setTournamentDate(selectedDate);
  };
  
  const handleSubmit = (e) => {
  
  
    if (isFormValid()) {
      setShowConfirmation(true);
      setError(''); // Clear error on successful submission
    } else {
      // Set an appropriate error message for free cash prize validation
      if (freeCashPrize) {
        const cashPrizeAmount = parseFloat(wager);
        if (!wager) {
          setError('Please enter a cash prize.');
        } else if (isNaN(cashPrizeAmount) || cashPrizeAmount <= 0) {
          setError('Cash prize must be greater than $0.');
        } else if (cashPrizeAmount > 500) {
          setError('Cash prize cannot exceed $500.');
        }
      } else {
        setError('All fields are required.');
      }
    }
  };

  const handleInputChange = () => {
    if (isFormValid()) {
      setError('');
    } else {
      if (hour && minute && size && mode && wager && tourneyName.length > 0) {
        if(freeCashPrize){
          const cashPrizeAmount = parseFloat(wager);
          console.log('error wager', wager);
          if (!wager) {
            setError('Please enter a cash prize.');
          } else if (isNaN(cashPrizeAmount) || cashPrizeAmount <= 0) {
            setError('Cash prize must be greater than $0.');
          } else if (cashPrizeAmount > 500) {
            setError('Cash prize cannot exceed $500.');
          }
        }else{
          setError('Set a start time that is at least 5 minutes from now.');
        }
      } else if (hour && minute && size && mode && wager && tourneyName.length === 0) {
        console.log('hitttt');
      }
    }
  };

  const isFormValid = () => {
    // Basic validation: Check if all required fields are filled
    if (!tourneyName || !hour || !minute || !size || !mode) {
      return false;
    }
  
    // Additional validation for free cash prize
    if (freeCashPrize) {
      const cashPrizeAmount = parseFloat(wager);
  
      // Check that the wager is a valid number, greater than 0, and no more than $500
      if (!wager || isNaN(cashPrizeAmount) || cashPrizeAmount <= 0 || cashPrizeAmount > 500) {
        return false;
      }
    }
  
    // Validate time: ensure selected time is at least 5 minutes from now
    const currentTime = new Date();
    const selectedTime = new Date();
    selectedTime.setHours(Number(hour));
    selectedTime.setMinutes(Number(minute));
  
    const diff = (selectedTime - currentTime) / 60000; // Difference in minutes
  
    if (diff < 5) {
      return false;
    }
  
    return true;
  };

  useEffect(() => {
    handleInputChange();
  }, [tourneyName, hour, minute, size, mode, wager]);

  const slides = [
    (
      <div key="variation" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Type</div>
        <div className="createGameSubtitle">Regular or free cash prize tournament?</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <div style={{ display: 'flex' }}>
              <input
                type="checkbox"
                id="freeCashPrize"
                name="freeCashPrize"
                value="free"
                checked={freeCashPrize}
                onChange={(e) => setFreeCashPrize(e.target.checked)} 
                className='w-1rem'
              />
              <label className='ml-1rem f-875 fg-n' htmlFor="freeCashPrize">Free Cash Prize Tournament</label>   
            </div>
           </div>
        </div>
      </div>
    ),
    (
      <div key="variation" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Tournament Name</div>
        <div className="createGameSubtitle">How will users identify this tournament?</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <input
                className={`tourney-input name ${error ? 'error' : ''}`}
                type="text"
                value={tourneyName}
                onChange={(e) => setTourneyName(e.target.value)}
                autoCorrect="off"
                autoCapitalize="off"
                placeholder="Tournament Name"
                style={{ color: '#000'}}
              />
          </div>
        </div>
      </div>
    ),
    (
      <div key="variation" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Date</div>
        <div className="createGameSubtitle">When will this tournament take place?</div>
        <div className="play-vs-content">
          <div className="create-game-component" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="date dropdown" style={{ overflow: 'visible' }}>
              <DatePicker
                selected={tournamentDate}
                onChange={handleDateChange}
                customInput={
                  <button
                    className={`tourney-input date ${error ? "error" : ""}`}
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      {tournamentDate.toLocaleDateString()}
                    </span>
                  </button>
                }
              />
            </div>
            <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ flex: '1' }}>
              <Dropdown 
                options={hourOptions} 
                selected={hour} 
                setSelected={setHour} 
                label=""
                className={error ? 'error' : ''}
              />
            </div>
            <div>
              <svg fill="#efeff1" height="30px" viewBox="-64 0 256 256" xmlns="http://www.w3.org/2000/svg" stroke="#efeff1">
                <path d="M76.846 80a4 4 0 0 1 4.002 4.002V107.6a4.002 4.002 0 0 1-4.002 4.003H53.001A4 4 0 0 1 49 107.599V84.002A4.002 4.002 0 0 1 53.001 80h23.845zm0 64a4 4 0 0 1 4.002 4.002V171.6a4.002 4.002 0 0 1-4.002 4.003H53.001A4 4 0 0 1 49 171.599v-23.597A4.002 4.002 0 0 1 53.001 144h23.845z" fillRule="evenodd"></path>
              </svg>
            </div>
            <div style={{ flex: '1' }}>
              <Dropdown 
                options={minuteOptions} 
                selected={minute} 
                setSelected={setMinute} 
                label=""
                className={error ? 'error' : ''}
              />
            </div>
            </div>
          </div>
        </div>
      </div>
    ),
    freeCashPrize ?
    (
      <div key="wager" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Prize</div>
        <div className="createGameSubtitle">How much will the winner win?(In USD)</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <div className="dropdown cash-prize-input-div tourney-input flex" style={{ flex: '1', maxWidth: 'none' }}>
                <span className="dollar-sign">$</span>
                <input
                  type="number"
                  value={wager}
                  onChange={(e) => setWager(e.target.value)}
                  placeholder="Prize"
                  className={`cash-prize-input wager-input ${error ? 'error' : ''}`}
                />
            </div>
          </div>
        </div>
      </div>
    )
    :
    (
      <div key="wager" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Entry Amount</div>
        <div className="createGameSubtitle">How much will each user need to enter?</div>
        <div className="play-vs-content">
          <div className="create-game-component">
              <Dropdown 
                options={wagerOptions} 
                selected={wager} 
                setSelected={setWager} 
                label="Entry Amount" 
              />
          </div>
        </div>
      </div>
    ),
    (
      <div key="mode" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Elo Range</div>
        <div className="createGameSubtitle">What are the competition limits for the tournament?</div>
        <div className="createGameSubtitle" style={{ color: 'rgb(136, 136, 136)', fontSize: '11px' }}>In relation to your Elo</div>
        <div className="play-vs-content">
          <div className="create-game-component" style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem' }}>
            <div style={{ flex: '1' }}>
              <Dropdown 
                options={minEloOptions} 
                selected={minElo} 
                setSelected={setMinElo} 
                label="" 
                className='room-size'
              />
            </div>
            <div>-</div>
            <div style={{ flex: '1' }}>
            <Dropdown 
              options={maxEloOptions} 
              selected={maxElo} 
              setSelected={setMaxElo} 
              label="" 
              className='time-control'
            />
            </div>
          </div>
        </div>
      </div>
    ),
    (
      <div key="variation" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Variation</div>
        <div className="createGameSubtitle">Choose which variation you want.</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <Dropdown
              options={['Standard', 'King of the Hill']}
              selected={variation}
              setSelected={setVariation}
              label="Variation"
              className="variation"
            />
          </div>
        </div>
      </div>
    ),
    (
      <div key="wager" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Room Size</div>
        <div className="createGameSubtitle">Maximun players allowed in the tournament?</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <Dropdown 
              options={sizeOptions} 
              selected={size} 
              setSelected={setSize} 
              label="Room Size" 
              className='room-size'
            />
          </div>
        </div>
      </div>
    ),
    (
      <div key="mode" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Time Control</div>
        <div className="createGameSubtitle">Which Time Control Do you want?</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <Dropdown 
              options={modeOptions} 
              selected={mode} 
              setSelected={setMode} 
              label="Time Control" 
              className='time-control'
            />
          </div>
        </div>
      </div>
    ),
  ];

  return (
    <div>
         <div className="play-vs-component tab-content-component" style={{ maxHeight: '193.1px'}}>
              <TransitionGroup
                style={{ maxHeight: '119.9px' }}
              >
                <CSSTransition
                  key={currentSlide}
                  timeout={300}
                  classNames="slide"
                >
                  {slides[currentSlide]}
                </CSSTransition>
              </TransitionGroup>
              <div className='cgButtonContainer'>
                <button type="button" className="cg-padding cg-back pe-a" onClick={handleBackClick} disabled={currentSlide === 0}>
                  <span>Back</span>
                </button>
                <button type="button" className="cg-padding cg-next pe-a" onClick={handleNextClick}>
                  <span>{currentSlide === slides.length - 1 ? 'Invite' : 'Next'}</span>
                </button>
              </div>
            </div>
      {showConfirmation && (
        <TournamentConfirmation
          tourneyName={tourneyName}
          variation={variation}
          hour={hour}
          minute={minute}
          wager={wager}
          freeCashPrize={freeCashPrize}
          cashPrizeAmount={freeCashPrize ? parseFloat(wager) : 0}
          minElo={minElo}
          maxElo={maxElo}
          roomSize={size}
          timeMode={mode}
          tournamentDate={tournamentDate}
          closeModal={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
};

export default TournamentForm;
import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

// Make sure to use your own publishable key here
const stripePromise = loadStripe('pk_test_51PyffX2MzYAtAm3QbD5q2SSwUSL0DdmMkFCraYrNQAIcfbQG0z6YFb0i18x3wKyyrNtG56PPXUlQANRcJhuv5C1i00EHxGulXx');

const GET_USER_BALANCE = gql`
  query GetUserBalance {
    getUserBalance {
      balance
    }
  }
`;

const DebitCardWithdrawalPage = () => {
  const [amount, setAmount] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const { loading, error: queryError, data } = useQuery(GET_USER_BALANCE, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  });

  const balance = data?.getUserBalance?.balance || 0;

  useEffect(() => {
    if (queryError) {
      setError('Failed to fetch balance.');
    }
  }, [queryError]);

  const stripe = useStripe();
  const elements = useElements();

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, ''); // Remove commas
    if (/^\d*\.?\d*$/.test(value)) { // Validate numeric input
      setAmount(value);
    }
  };
  

  const handleWithdraw = async () => {
    if (!amount) {
      setError('Please enter an amount.');
      return;
    }

    if (parseFloat(amount) > parseFloat(balance)) {
      setError('Insufficient balance for this withdrawal.');
      return;
    }

    if (parseFloat(amount) < 1) {
      setError('The minimum withdrawal amount is $1.');
      return;
    }
    if (parseFloat(amount) > 100) {
      setError('The maximum withdrawal amount is $100.');
      return;
    }

    
    if (!stripe || !elements) {
      setError('Stripe.js has not loaded yet.');
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      const { token, error: tokenError } = await stripe.createToken(elements.getElement(CardElement));
      if (tokenError || !token) {
        throw new Error(tokenError?.message || 'Failed to tokenize card details.');
      }

      const authToken = localStorage.getItem('token');

      const response = await axios.post('https://grandhustler.com/api/withdraw', {
        amount: parseFloat(amount),
        currency: 'usd',
        destination: token.id, 
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`, // Include your token here
        }
      });

      if (response.status === 200) {
        alert(`Successfully withdrawn $${amount}`);
        setAmount('');
      } else {
        throw new Error('Failed to withdraw funds');
      }
    } catch (err) {
      setError('An error occurred while processing your withdrawal. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRemoveAccount = () => {
    alert('Removing account');
  };

  return (
    <div className="debit-card-withdrawal">
      <div className="content withdraw scroll">
        <div className="margin">
          <div className="balance-info">
            <p className="withdrawable-balance reg-font">Withdrawable balance: ${balance}</p>
          </div>
          <div className="withdraw-form">
            <div className="amount-section">
              <label htmlFor="custom_amount" className="amount-label">
                <span>Amount</span>
              </label>
              <div className="amount-input">
                <div className="currency-sign">$</div>
                <input
                  type="text"
                  inputMode="decimal"
                  pattern="^\d*\.?\d*$"
                  tabIndex="0"
                  id="custom_amount"
                  value={amount}
                  onChange={handleAmountChange}
                  min="1"
                  max="100000"
                  step="0.01"
                  disabled={isProcessing}
                />
              </div>
            </div>

            {/* Card Information Section */}
            <div className="card-info">
            
              <CardElement
                id="cardNumber"
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
            </div>

            {error && <div className="error-message">{error}</div>}

            <section className="account-info">
              <div className="actions">
                <div className="submit-withdraw-container">
                  <button
                    type="button"
                    tabIndex="0"
                    className="withdraw-button"
                    onClick={handleWithdraw}
                    disabled={!amount || isProcessing}
                  >
                    {isProcessing ? 'Processing...' : `Withdraw $${amount || '0.00'}`}
                  </button>
                </div>
                <div className="info">
                  <span className='light-weight'>Debit Card Withdrawals will be processed within <strong>24 hours.</strong></span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

// Wrap your component with Stripe Elements provider
const WrappedDebitCardWithdrawalPage = (props) => (
  <Elements stripe={stripePromise}>
    <DebitCardWithdrawalPage {...props} />
  </Elements>
);

export default WrappedDebitCardWithdrawalPage;
import React from 'react';

// SVG icon component for reusability
const NewsIcon = () => (
  <svg viewBox="0 0 24 24" width="24px" height="24px"  version="1.1" style={{ fill: '#242226' }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000">
    <title>news_line</title>
    <g id="SVGRepo_iconCarrier">
      <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" fillRule="nonzero" />
      <path d="M16,3 C17.0543909,3 17.9181678,3.81587733 17.9945144,4.85073759 L18,5 L18,10 L19.5,10 C20.2796706,10 20.9204457,10.5949121 20.9931332,11.3555442 L21,11.5 L21,19 C21,20.597725 19.7511226,21.903664 18.1762773,21.9949075 L18,22 L5,22 C3.94563773,22 3.08183483,21.18415 3.00548573,20.1492661 L3,20 L3,5 C3,3.94563773 3.81587733,3.08183483 4.85073759,3.00548573 L5,3 L16,3 Z M19,12 L18,12 L18,20 C18.5523,20 19,19.5523 19,19 L19,12 Z M16,5 L5,5 L5,20 L16,20 L16,5 Z M11,13 C11.5523,13 12,13.4477 12,14 C12,14.51285 11.613973,14.9355092 11.1166239,14.9932725 L11,15 L8,15 C7.44772,15 7,14.5523 7,14 C7,13.48715 7.38604429,13.0644908 7.88337975,13.0067275 L8,13 L11,13 Z M13,8 C13.5523,8 14,8.44772 14,9 C14,9.55228 13.5523,10 13,10 L8,10 C7.44772,10 7,9.55228 7,9 C7,8.44772 7.44772,8 8,8 L13,8 Z" fill="#a7a7a7" />
    </g>
  </svg>
);

const NewsSection = ({ isCollapsed }) => (
  <div className='w-100'>
  <div className="menu-group news-section flex fd-c gap-16rem">
    <div className="group-title flex fg-0" style={{ justifyContent: isCollapsed ? 'center' : '' }}>
      <span 
        className="icon-title" 
        style={{ display: isCollapsed ? 'none' : 'inline' }}
      >
        News
      </span>
    </div>
    <a href="https://www.chess.com/news/view/2024-norway-chess-preview"  style={{ padding: '6px 6px 6px 12px' }}>
      <div className="flex ai-c">
        <div className="chesscom" style={{ borderRadius: '0.5rem', width: '40px', height: '40px', marginLeft: isCollapsed ? '4px' : '0' }}></div>
        <div className="flex fd-c articleInfo jc-c" style={{ display: isCollapsed ? 'none' : '' }}>
          <span className="font ol-nw">Norway Chess 2024: 7 Talking Points</span>
        </div>
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M6 9L12 15L18 9"
              stroke="#FFFFFF1a"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </svg>
      </div>
    </a>
    <a href="https://www.chess.com/news/view/2024-norway-chess-round-1" style={{ padding: '6px 6px 6px 12px' }}>
      <div className="flex ai-c">
        <div className="chesscom" style={{ borderRadius: '0.5rem', width: '40px', height: '40px', marginLeft: isCollapsed ? '4px' : '0' }}></div>
        <div className="flex fd-c articleInfo jc-c" style={{ display: isCollapsed ? 'none' : '' }}>
          <span className="font ol-nw">Carlsen, Nakamura, Praggnanandhaa Lead After Armageddon</span>
        </div>
        <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M6 9L12 15L18 9"
        stroke="#FFFFFF1a"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
      </div>
    </a>
    <a href="https://lichess.org/@/NoelStuder/blog/chess-blunder-check-stop-hanging-pieces-and-avoid-frustration/cBKjkANe"  style={{ padding: '6px 6px 6px 12px' }}>
      <div className="flex ai-c">
        <div className="lichess" style={{ borderRadius: '0.5rem', width: '40px', height: '40px', marginLeft: isCollapsed ? '4px' : '0' }}></div>
        <div className="flex fd-c articleInfo jc-c" style={{ display: isCollapsed ? 'none' : '' }}>
          <span className="font ol-nw">Chess Blunder Check: Stop Hanging Pieces And Avoid Frustration</span>
        
        </div>
        <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M6 9L12 15L18 9"
        stroke="#FFFFFF1a"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
      </div>
    </a>
    <a href="https://lichess.org/@/NoelStuder/blog/chess-blunder-check-stop-hanging-pieces-and-avoid-frustration/cBKjkANe"  style={{ padding: '6px 6px 6px 12px' }}>
      <div className="flex ai-c">
        <div className="lichess" style={{ borderRadius: '0.5rem', width: '40px', height: '40px', marginLeft: isCollapsed ? '4px' : '0' }}></div>
        <div className="flex fd-c articleInfo jc-c" style={{ display: isCollapsed ? 'none' : '' }}>
          <span className="font ol-nw">Chess Blunder Check: Stop Hanging Pieces And Avoid Frustration</span>
        </div>
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M6 9L12 15L18 9"
              stroke="#FFFFFF1a"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </svg>
      </div>
    </a>
  </div>
  </div>
);

export default NewsSection;
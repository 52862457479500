import React, { useState } from 'react';
import InsufficientFundsModal from './InsufficientFundsModal';

function TournamentConfirmation({ tourneyName, variation, hour, minute, wager, freeCashPrize, cashPrizeAmount, minElo, maxElo, roomSize, timeMode, tournamentDate, closeModal }) {
    const [showInsufficientFundsModal, setShowInsufficientFundsModal] = useState(false);
    const [timeError, setTimeError] = useState(false); // New state to handle time error

    const handleYesClick = async () => {
        const success = await handleCreateTournament();
        if (success) {
            closeModal();
            window.location.reload();
        } 
    };

    return (
        <>
            {showInsufficientFundsModal ? (
                <InsufficientFundsModal
                    tourneyName={tourneyName}
                    closeModal={() => setShowInsufficientFundsModal(false)}
                />
            ) : (
                <div className="modal-backdrop">
                    <div className="backdrop-padding">
                        <div className="container-center">
                            <div className="unfollow-question-div">
                                <div className="inner-padding">
                                    <div className="question">Create and Join Swiss Tournament, {tourneyName}?</div>
                                    <div className="sidenote" style={{ marginBottom: '8px' }}>
                                        Create tournament "{tourneyName}" with a {wager} entry fee?
                                    </div>
                                    <div className="sidenote">You can join lobby 5 minutes before game time starts.</div>
                                    <div className="sidenote error">MAKE SURE MILITARY TIME {hour}:{minute} IS CORRECT.</div>

                                    {/* Display time error message if time is less than 5 minutes */}
                                    {timeError && (
                                        <div className="sidenote error" style={{ color: 'red' }}>
                                            Must pick a time more than 5 min from now.
                                        </div>
                                    )}

                                    <div className="action-buttons">
                                        <button className="no" onClick={closeModal}>No, cancel</button>
                                        <div className="ml">
                                            <button className="yes" onClick={handleYesClick}>
                                                <div>Yes, Create</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="x-button"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    async function handleCreateTournament() {
        try {
            if (!sixMinFromNow()) {
                setTimeError(true); 
                return false;
            } else {
                setTimeError(false);
            }

            const hasSufficientFunds = await checkFunds(wager);
            if (hasSufficientFunds) {
                await putMoneyInPot(wager);
                await postTourney(wager, freeCashPrize, cashPrizeAmount);
                return true;
            } else {
                setShowInsufficientFundsModal(true); // Only show if funds are insufficient and time is valid
                return false;
            }
        } catch (error) {
            console.error('Error joining tournament:', error);
            return false;
        }
    }

    function sixMinFromNow() {
        const now = new Date();
        const providedTime = new Date();

        providedTime.setHours(parseInt(hour, 10));
        providedTime.setMinutes(parseInt(minute, 10));
        providedTime.setSeconds(0);
        providedTime.setMilliseconds(0);

        const diff = providedTime - now;
        const diffInMinutes = diff / (1000 * 60);

        return diffInMinutes >= 5;
    }

    async function checkFunds(wager) {
        try {
            const token = localStorage.getItem('token'); 
            const cleanedWager = cleanWagerInput(wager);

          const response = await fetch('https://grandhustler.com/graphql', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` 
            },
            body: JSON.stringify({
              query: `
                query SufficientFunds($wager: Float!) {
                  sufficientFunds(wager: $wager)
                }
              `,
              variables: { wager: cleanedWager }
            }),
            credentials: 'include'
          });
          
          const result = await response.json();
          console.log('result', result.data.sufficientFunds);
      
          if (result.errors) {
            throw new Error(result.errors[0].message);
          }
          
          return result.data.sufficientFunds;
        } catch (error) {
          console.error('Error checking funds:', error);
          return false;
        }
      }

      function cleanWagerInput(input) {
        return parseFloat(input.replace(/,/g, ''));
      };
      
      async function putMoneyInPot(wager) {
        try {
            const token = localStorage.getItem('token');
            const cleanedWager = cleanWagerInput(wager);
        
            if (!token) {
                throw new Error('No token found in localStorage');
            }
        
            const response = await fetch('https://grandhustler.com/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    query: `
                        mutation PutMoneyInPot($wager: Float!, $join: Boolean!) {
                            putMoneyInPot(wager: $wager, join: $join)
                        }
                    `,
                    variables: { 
                        wager: cleanedWager,
                        join: freeCashPrize ? true : false,
                    }
                })
            });
        
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
            }
        
            const data = await response.json();
            if (data.errors) {
                throw new Error(`GraphQL error: ${data.errors.map(err => err.message).join(', ')}`);
            }
        
            console.log('Money put in pot:', data);
            return data;
        } catch (error) {
            console.error('Error putting money in pot:', error);
        }
    }

    async function postTourney(wager, freeCashPrize, cashPrizeAmount) {
        try {
            const name = tourneyName;
            const time = `${hour}:${minute}`;
            const size = determineSize(roomSize);
            const mode = determineGameMode(timeMode);
            const cleanedWager = cleanWagerInput(wager);
            const token = localStorage.getItem('token');
    
            const query = freeCashPrize
                ? `
                    mutation CreateFreeCashTournament(
                      $name: String!,
                      $variation: String!,
                      $time: String!,
                      $date: String!,
                      $maxParticipants: Int!,
                      $timeMode: String!,
                      $gameMode: String!,
                      $cashPrizeAmount: Float!,
                      $minElo: String!,
                      $maxElo: String!
                    ) {
                        createFreeCashTournament(
                            name: $name,
                            variation: $variation,
                            time: $time,
                            date: $date,
                            maxParticipants: $maxParticipants,
                            timeMode: $timeMode,
                            gameMode: $gameMode,
                            cashPrizeAmount: $cashPrizeAmount,
                            minElo: $minElo,
                            maxElo: $maxElo
                        ) {
                            id
                            name
                            time
                            date
                            maxParticipants
                            currentParticipants
                            createdAt
                            updatedAt
                            wager
                            active
                            timeMode
                            gameMode
                            roomId
                            participants
                            freeCashPrize
                            cashPrizeAmount
                            winnerId
                            creatorId
                            minElo
                            maxElo
                        }
                    }
                `
                : `
                    mutation CreateTournament(
                      $name: String!,
                      $variation: String!,
                      $time: String!,
                      $date: String!,
                      $maxParticipants: Int!,
                      $wager: Int!,
                      $timeMode: String!,
                      $gameMode: String!,
                      $roomId: String!,
                      $participants: [Int],
                      $winnerId: Int,
                      $creatorId: Int,
                      $minElo: String!,
                      $maxElo: String!
                    ) {
                        createTournament(
                            name: $name,
                            variation: $variation,
                            time: $time,
                            date: $date,
                            maxParticipants: $maxParticipants,
                            wager: $wager,
                            timeMode: $timeMode,
                            gameMode: $gameMode,
                            roomId: $roomId,
                            participants: $participants,
                            winnerId: $winnerId,
                            creatorId: $creatorId,
                            minElo: $minElo,
                            maxElo: $maxElo
                        ) {
                            id
                            name
                            time
                            date
                            maxParticipants
                            currentParticipants
                            createdAt
                            updatedAt
                            wager
                            active
                            timeMode
                            gameMode
                            roomId
                            participants
                            freeCashPrize
                            cashPrizeAmount
                            winnerId
                            creatorId
                            minElo
                            maxElo
                        }
                    }
                `;
    
            const variables = freeCashPrize
                ? {
                    name,
                    variation,
                    time,
                    date: tournamentDate,
                    maxParticipants: parseInt(size, 10),
                    timeMode,
                    gameMode: mode,
                    cashPrizeAmount: cashPrizeAmount,
                    minElo,
                    maxElo
                }
                : {
                    name,
                    variation,
                    time,
                    date: tournamentDate,
                    maxParticipants: parseInt(size, 10),
                    wager: parseInt(cleanedWager, 10),
                    timeMode,
                    gameMode: mode,
                    roomId: 'some-room-id', // Replace this with actual logic for roomId
                    participants: null,
                    winnerId: null,
                    creatorId: null,
                    minElo,
                    maxElo
                };
    
            const response = await fetch('https://grandhustler.com/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ query, variables })
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
            }
    
            const data = await response.json();
            if (data.errors) {
                throw new Error(`GraphQL error: ${data.errors.map(err => err.message).join(', ')}`);
            }
    
            console.log('Tournament created:', data);
            return data;
        } catch (error) {
            console.error('Error posting tournament:', error);
            return false;
        }
    }
    
    function determineSize(sizeText) {
        if (sizeText === '4-Man') return '4';
        if (sizeText === '8-Man') return '8';
        return '999999';
    }

    function determineGameMode(time) {
        if (time === "10 Min" || time === "30 Min") {
            return "Classical";
        } else if (time === "3 Min" || time === "3 | 2" || time === "5 Min") {
            return "Blitz";
        } else if (time === "1 Min") {
            return "Bullet";
        } else {
            return "Unlimited";
        }
    }
}
export default TournamentConfirmation;
import React, { useState, useEffect } from 'react';
import Dropdown from './HomeDropdowns';
import PostPhysicalTournamentForm from './postPhysicalTournament';
import PhysicalTournamentModal from './PhysicalTourneyModal';
import { useQuery, gql } from '@apollo/client';

const GET_PHYSICAL_TOURNAMENTS = gql`
  query GetPhysicalTournaments($state: String!) {
    getPhysicalTournaments(state: $state) {
      title
      street
      city
      date
      hour
      minute
      bio
      type
    }
  }
`;

const states = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];

const PhysicalGrid = () => {
   const [state, setState] = useState('CA');
   const [isFormVisible, setIsFormVisible] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false); 
   const [selectedTournament, setSelectedTournament] = useState(null);

   const { loading, error, data } = useQuery(GET_PHYSICAL_TOURNAMENTS, {
      variables: { state },
   });
   const stateName = states.find((s) => s.value === state)?.name || 'Unknown State';

   console.log('TOURNAMENTS', data);
   const handleStateChange = (selectedName) => {
    const selectedState = states.find((s) => s.name === selectedName);
    if (selectedState) setState(selectedState.value);
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible); 
  };

  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    if (isNaN(date)) {
      console.error('Invalid date');
      return 'Invalid date';
    }
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${month}-${day}`;
  };

  const openModal = (tournament) => {
    setSelectedTournament(tournament); // Set the tournament for modal
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedTournament(null); // Clear the selected tournament
  };

return (
  <div className='media-group' style={{ padding: '1rem 2rem' }}>
    <div className='medium-title' style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <span style={{ fontWeight: 600 }}>{stateName}</span>
      <div style={{ display: 'flex', fontSize: '13px', marginTop: '4px', alignItems: 'center' }}>
        <Dropdown
            style={{
              border: '2px solid #2b2b2b',
              padding: '4px 8px',
              fontWeight: 600,
            }}
            options={states.map((s) => s.name)} // Pass state names as options
            defaultOption={stateName}
            onChange={handleStateChange}
            overview={true}
          />  
          <button
            style={{
              padding: '4px 8px',
              fontWeight: 600,
              background: 'transparent',
              color: 'rgb(172, 255, 195)',
              display: 'flex',
              paddingLeft: 'none',
              marginLeft: '0.5rem'
            }}
            onClick={toggleFormVisibility}
          >
            {isFormVisible ? (
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '20px' }}>
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6 12L18 12"
                  stroke="rgb(172, 255, 195)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <title>Remove Form</title>
              </g>
            </svg>
            )
            :
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="rgb(172, 255, 195)" stroke="rgb(172, 255, 195)" style={{ width: '16px', marginRight: '4px'}}>
                 <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                 <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                 <g id="SVGRepo_iconCarrier">
                   <defs>
                     <style>{`.cls-1 { fill: none; stroke: rgb(172, 255, 195); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; }`}</style>
                   </defs>
                   <title>Create Physical Tournament</title>
                   <g id="plus">
                     <line className="cls-1" x1="16" x2="16" y1="7" y2="25" />
                     <line className="cls-1" x1="7" x2="25" y1="16" y2="16" />
                   </g>
                 </g>
             </svg>
            }
          </button>
        </div>
    </div>
    <div>
      <div className="page-main">
        <section>
          <div className="groupsHome">
            <div className="flex">
              <div className="listCard-title">Physical Tournaments</div>
              <div className="listCard-title-sm">Find tournaments near you!</div>
            </div>
            <div className="w-100 flex fd-c">
              {isFormVisible && <PostPhysicalTournamentForm />} {/* Conditionally render the form */}
            </div>
            <div className="responsive-table">
              <div className="flex">
                <div className="scroller">
                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                    <thead className="table-head">
                      <tr className="table-tr">
                        <th className="table-th" style={{ borderTop: 'none', borderBottom: '1px solid rgb(43, 43, 43)', padding: '1rem 0' }}>Group Name</th>
                        <th className="table-th grouplist-card" style={{ borderTop: 'none', borderBottom: '1px solid rgb(43, 43, 43)'}}>Date</th>
                        <th className="table-th grouplist-card" style={{ borderTop: 'none', borderBottom: '1px solid rgb(43, 43, 43)'}}>Time</th>
                        <th className="table-th grouplist-card" style={{ borderTop: 'none', borderBottom: '1px solid rgb(43, 43, 43)'}}>City</th>
                        <th className="group-join table-th grouplist-card" style={{ borderTop: 'none', borderBottom: '1px solid rgb(43, 43, 43)'}}></th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {data && data.getPhysicalTournaments && data.getPhysicalTournaments.map((tournament, index) => (
                        <tr className="table-tr">
                          <td className="table-td">
                            <div className="group">
                              <div className="headshot"></div>
                              <a href={`/ghsite/public/tournamentClassic.html`}>{tournament.title}</a>
                            </div>
                          </td>
                          <td className="group-size table-td grouplist-card flex jc-c">{formatDate(tournament.date)}</td>
                          <td className="group-size table-td grouplist-card">{tournament.hour}:{tournament.minute}</td>
                          <td className="group-size table-td grouplist-card">{tournament.city}</td>
                          <td className="group-join table-td grouplist-card">
                            <button 
                              className="join-tournament"
                              onClick={() => openModal(tournament)} 
                            >
                              <span>View</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    {isModalOpen && selectedTournament && (
        <PhysicalTournamentModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={selectedTournament.title}
          street={selectedTournament.street}
          city={selectedTournament.city}
          state={state}
          date={formatDate(selectedTournament.date)}
          hour={selectedTournament.hour}
          minute={selectedTournament.minute}
          bio={selectedTournament.bio}
          type={selectedTournament.type}
        />
      )}
    </div>
  );
};

export default PhysicalGrid;

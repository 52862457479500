import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoggedInSection from './LoggedInHeader';
import FullSearchBar from './FullSearchBar';
import { jwtDecode } from 'jwt-decode'; // Make sure you import jwtDecode correctly
import { useQuery, gql } from '@apollo/client';
import SkeletonLoaderWrapper from './SkeletonLoaderWrapper';

import '../css/header.css';

const GET_USER = gql`
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      id
      username
      profilePic
      balance
    }
  }
`;

const Header = ({ onToggleSidebar }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({ username: '', profilePic: '', balance: 0 });
  const [userId, setUserId] = useState('');

  const { data, loading, error } = useQuery(GET_USER, {
    variables: { userId },
    skip: !userId,
  });

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      setIsLoggedIn(true);

      try {
        // Decode the token to get user information
        const decodedToken = jwtDecode(token);
        const userIdFromToken = decodedToken.userId; // Adjust based on your token structure
        setUserId(userIdFromToken);
      } catch (error) {
        console.error('Error decoding token', error);
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setUserInfo({
        username: data.getUser.username,
        profilePic: data.getUser.profilePic,
        balance: data.getUser.balance,
      });
    }
  }, [data]);

  if (loading) return <div></div>;
  if (error) return <div>Error loading user data</div>;

  return (
    <header className="top-page">
      <div className="v2">
        <nav className="nav-header">
          {data ? (
            <FullSearchBar />
          )
          :(
            <SkeletonLoaderWrapper height="36px" />
          )}
          <div className="spc-between">
            <div id="end" className="hth-masthead flex">
              <div className="register-buttons">
                {isLoggedIn ? (
                  <LoggedInSection username={userInfo.username} profilePic={userInfo.profilePic} balance={userInfo.balance}/>
                ) : (
                  <>
                    <Link to="/login">
                      <button id="login-button" className="button login auth-container">
                        <span className="login-ijk2lq">Log In</span>
                      </button>
                    </Link>
                    <Link to="/register">
                      <button id="register-button" className="button register auth-container">
                        <span className="login-ijk2lq">Register</span>
                      </button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from './DropdownOptions';
import InsufficientFundsModal from './InsufficientFundsModal';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useWebSocket } from '../contexts/WebSocketContext';

const PlayVS = ({ username, mutual, onSubmit }) => {
  const [wager, setWager] = useState('50,000');
  const [mode, setMode] = useState('5 Min');
  const [variation, setVariation] = useState('Standard');
  const [color, setColor] = useState('random');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showInsufficientFundsModal, setShowInsufficientFundsModal] = useState(false);

  const { socket } = useWebSocket();

  const variationOptions = ['Standard', 'King of the Hill'];
  const wagerOptions = ['0', '10,000', '50,000', '100,000', '200,000'];
  const modeOptions = ['1 Min', '2 | 1', '3 Min', '3 | 2', '5 Min', '10 Min'];

  const determineGameMode = (time) => {
    if (time === '10 Min') {
      return 'Classical';
    } else if (['3 Min', '3 | 2', '5 Min'].includes(time)) {
      return 'Blitz';
    } else if (['1 Min', '2 | 1'].includes(time)) {
      return 'Bullet';
    } else {
      return 'Unknown';
    }
  };

  const handleNextClick = async () => {
    if (currentSlide < slides.length - 1) {
      //take to next slide
      setCurrentSlide(currentSlide + 1)
    } else {
      //invite
      await handleClick()
    }
  };

  const handleBackClick = () => {
    if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
  };

  const handleClick = async () => {
    const hasSufficientFunds = await checkFunds(wager);
    if (hasSufficientFunds) {
      putMoneyInPot(wager);
      const gameType = determineGameMode(mode);
      const invitationData = {
        receiverId: mutual.id,
        variation: variation,
        time: mode,
        wager: cleanWagerInput(wager),
        gameType,
        color,
      };

      socket.emit('send_invitation', invitationData);
      await sendInvitation(invitationData);
      onSubmit();
    } else {
      setShowInsufficientFundsModal(true); 
      console.log('insufficient funds');
    }
  };

  async function checkFunds(wager) {
    try {
      const token = localStorage.getItem('token');
      const cleanedWager = cleanWagerInput(wager);

      const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            query SufficientFunds($wager: Float!) {
              sufficientFunds(wager: $wager)
            }
          `,
          variables: { wager: cleanedWager }
        }),
        credentials: 'include'
      });

      const result = await response.json();
      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      return result.data.sufficientFunds;
    } catch (error) {
      console.error('Error checking funds:', error);
      return false;
    }
  }

  async function putMoneyInPot(wager) {
    try {
      const token = localStorage.getItem('token');
      const cleanedWager = cleanWagerInput(wager);
      const freeCashPrize = false;

      if (!token) {
        throw new Error('No token found in localStorage');
      }

      const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            mutation PutMoneyInPot($wager: Float!, $join: Boolean!) {
              putMoneyInPot(wager: $wager, join: $join)
            }
          `,
          variables: { 
            wager: cleanedWager,
            join: freeCashPrize ? true : false,
          }
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
      }

      const data = await response.json();
      if (data.errors) {
        throw new Error(`GraphQL error: ${data.errors.map(err => err.message).join(', ')}`);
      }

      return data;
    } catch (error) {
      console.error('Error putting money in pot:', error);
    }
  }

  async function sendInvitation(invitationData) {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            mutation CreateInvitation($input: InvitationInput!) {
              createInvitation(input: $input) {
                id
                senderId
                receiverId
                status
                time
                wager
                gameType
                color
                roomId
              }
            }
          `,
          variables: { input: invitationData }
        }),
        credentials: 'include'
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
    }
  }

  function cleanWagerInput(input) {
    return parseFloat(input.replace(/,/g, ''));
  }

  const slides = [
    (
      <div key="variation" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Variation</div>
        <div className="createGameSubtitle">Choose which variation you want.</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <Dropdown
              options={['Standard', 'King of the Hill']}
              selected={variation}
              setSelected={setVariation}
              label="Variation"
              className="variation"
            />
          </div>
        </div>
      </div>
    ),
    (
      <div key="wager" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Wager</div>
        <div className="createGameSubtitle">How much will each user wager?</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <Dropdown
              options={['0', '10,000', '50,000', '100,000', '200,000']}
              selected={wager}
              setSelected={setWager}
              label="Wager Amount"
              className="amount"
            />
          </div>
        </div>
      </div>
    ),
    (
      <div key="mode" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Time Control</div>
        <div className="createGameSubtitle">Which Time Control Do you want?</div>
        <div className="play-vs-content">
          <div className="create-game-component">
            <Dropdown
              options={['1 Min', '2 | 1', '3 Min', '3 | 2', '5 Min', '10 Min']}
              selected={mode}
              setSelected={setMode}
              label="Time Control"
              className="mode"
            />
          </div>
        </div>
      </div>
    ),
    (
      <div key="color" style={{ maxHeight: '119.9px' }}>
        <div className='createGameTitle'>Color</div>
        <div className="createGameSubtitle">Which color will you be?</div>
        <div className="custom-game-fields">
          <div className="custom-game-field-component">
            <button
              className={`color-selector-option color-selector-white ${color === 'white' ? 'color-selected' : ''}`}
              data-color="white"
              onClick={() => setColor('white')}
              style={{ color: '#000'}}
            >
              White
            </button>
            <button
              className={`color-selector-option color-selector-random ${color === 'random' ? 'color-selected' : ''}`}
              data-color="random"
              onClick={() => setColor('random')}
            >
              Random
            </button>
            <button
              className={`color-selector-option color-selector-black ${color === 'black' ? 'color-selected' : ''}`}
              data-color="black"
              onClick={() => setColor('black')}
              style={{ color: '#FFF'}}
            >
              Black
            </button>
          </div>
        </div>
      </div>
    )
  ];


  return (
    <div className="play-vs-component tab-content-component" style={{ maxHeight: '193.1px'}}>
      <TransitionGroup
        style={{ maxHeight: '119.9px' }}
      >
        <CSSTransition
          key={currentSlide}
          timeout={300}
          classNames="slide"
        >
          {slides[currentSlide]}
        </CSSTransition>
      </TransitionGroup>
      <div className='cgButtonContainer'>
        <button type="button" className="cg-padding cg-back pe-a" onClick={handleBackClick} disabled={currentSlide === 0}>
          <span>Back</span>
        </button>
        <button type="button" className="cg-padding cg-next pe-a" onClick={handleNextClick}>
          <span>{currentSlide === slides.length - 1 ? 'Invite' : 'Next'}</span>
        </button>
      </div>
      {showInsufficientFundsModal && (
        <InsufficientFundsModal
            tourneyName={username}
            closeModal={() => setShowInsufficientFundsModal(false)}
        />
      )}
    </div>
  );
};

PlayVS.propTypes = {
  username: PropTypes.string.isRequired,
  mutual: PropTypes.shape({
    profilePic: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PlayVS;

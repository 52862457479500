import React from 'react';

const TvPlayer = () => {
  return (
    <div className="tv-margin">
      <span style={{ color: '#fff' }}>Chess TV</span>
      <div className="m-1" style={{ marginTop: '1rem' }}>
        <div className="tv-player-component">
          <iframe
            allow="autoplay; fullscreen"
            className="tv-iframe"
            src="//player.twitch.tv/?channel=caseoh_&autoplay=true&muted=false&parent=grandhustler.com"
            frameBorder="0"
            height="258"
            width="550"
            title="Twitch Stream"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default TvPlayer;
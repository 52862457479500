import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Replace with your Stripe public key
const stripePromise = loadStripe('pk_test_51PyffX2MzYAtAm3QbD5q2SSwUSL0DdmMkFCraYrNQAIcfbQG0z6YFb0i18x3wKyyrNtG56PPXUlQANRcJhuv5C1i00EHxGulXx');

const formatAmount = (value) => {
    const cleanedValue = value.replace(/[^0-9.]/g, '');
    const [integerPart, decimalPart] = cleanedValue.split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);

    const handleAmountChange = (event) => {
        const value = event.target.value;
        setAmount(formatAmount(value));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements || !amount) return;

        setLoading(true);

        const cardElement = elements.getElement(CardElement);

        try {
            // Create payment method
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.error(error);
                alert('Payment method creation failed');
                setLoading(false);
                return;
            }

            const response = await fetch('https://grandhustler.com/api/deposit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,  // Add token from localStorage
                },
                body: JSON.stringify({
                    paymentMethodId: paymentMethod.id,
                    amount,
                }),
            });
            
            const result = await response.json();
            console.log('result', result);
            
            if (result.success) {
                if (result.redirectUrl) {
                    // Redirect to Stripe onboarding page
                    window.location.href = result.redirectUrl;
                } else {
                    alert(`Payment successful: ${result.transactionId}`);
                }
            } else {
                alert('Payment failed');
            }
            
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="deposit_amount">Deposit Amount</label>
            <input
                type="text"
                id="deposit_amount"
                placeholder="Min $5.00"
                value={amount}
                onChange={handleAmountChange}
            />
            <label htmlFor="card-element">Card Details</label>
            <CardElement id="card-element" />
            <button type="submit" disabled={!stripe || loading}>
                {loading ? 'Processing...' : 'Submit'}
            </button>
        </form>
    );
};

const DebitCard = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default DebitCard;

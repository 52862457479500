import React, { useEffect, useState } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useUser } from '../contexts/UserContext';
import Header from './Header';
import Sidebar from './SideBar';
import OwnershipFooter from './OwnershipFooter';
import Footer from './Footer';
import GameList from './GameList';
import BetSlip from './BetSlip';
import WeeklyEloBox from './WeeklyEloBox';
import DailyComparisonChart from './DailyComparisonChart';
import FinancialBarChart from './WinBarChart';
import FinancialDonutChart from './DonutChart';
import AdBanner from './AdBanner';
import GameScroll from './GameHistory';
import Matches from './Scoreboard';
import Ticker from './WinnerTicker';
import TvPlayer from './TVPlayer';
import PhysicalTourneys from './PhysicalTourneys';
import Modal from './CreateGameModal';
import MessageTab from './MessageModal';
import NotificationMessage from './NotificationMessage';
import Dropdown from './HomeDropdowns';
import SkeletonLoaderWrapper from './SkeletonLoaderWrapper';
import OnlineFriend from './OnlineFriends';
import '../css/chessImages.css';
import '../css/homepage.css';

const MainLayout = () => {
  const { socket, joinUserRoom } = useWebSocket();
  const user = useUser();
  console.log('user', user);
  const [isMessageTabOpen, setIsMessageTabOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // New state to track sidebar collapse
  const [historyLength, setHistoryLength] = useState('Last 7 days');
  const [timeControlType, setTimeControlType] = useState('10:00');
  const [variation, setVariationType] = useState('Standard');
  const [chartData, setChartData] = useState({ win: 0, loss: 0, draw: 0 }); // Chart data state

  useEffect(() => {
    if (socket && user) {
      joinUserRoom(user.id); // Join the room when the user is available
    }
  }, [socket, user, joinUserRoom]);

  useEffect(() => {
    if (socket) {
      socket.on('rejected', (data) => {
        setNotificationTitle('Invitation Rejected');
        setNotificationMessage('User has rejected your invite.');
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      });

      return () => {
        socket.off('rejected');
      };
    }
  }, [socket]);

  const handleToggleMessageTab = () => {
    setIsMessageTabOpen((prev) => !prev);
  };

  const handleOpenCreateGame = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitModal = () => {
    setShowModal(false);
    setNotificationTitle('Invitation Sent');
    setNotificationMessage('Invitation sent to user.');
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev); // Toggle the sidebar state
  };

  useEffect(() => {
    fetchWinChartData(historyLength, timeControlType, variation);

    const mediaHolder = document.querySelector('.media-holder');
    const chessbookWrapper = document.querySelector('.chessbook-wrapper');

    const checkWidth = () => {
      if (window.innerWidth < 1330) {
        if (mediaHolder && chessbookWrapper && !chessbookWrapper.parentNode.isEqualNode(mediaHolder.parentNode)) {
          mediaHolder.parentNode.insertBefore(chessbookWrapper, mediaHolder);
        }
      } else {
        if (mediaHolder && chessbookWrapper && !mediaHolder.contains(chessbookWrapper)) {
          mediaHolder.appendChild(chessbookWrapper);
        }
      }
    };

    checkWidth(); // Initial check on mount

    window.addEventListener('resize', checkWidth);

    return () => {
      window.removeEventListener('resize', checkWidth); // Cleanup listener on unmount
    };
  }, []);
  const handleWinHistoryLengthChange = (selected) => {
    setHistoryLength(selected); // Update state
    fetchWinChartData(selected, timeControlType, variation); 
  };

  const handleWinTimeControlTypeChange = (selected) => {
    setTimeControlType(selected); // Update state
    fetchWinChartData(historyLength, selected, variation);
  };
  const handleVariationTypeChange = (selected) => {
    setVariationType(selected); // Update state
    fetchWinChartData(historyLength, timeControlType, selected);
  };

  
const fetchWinChartData = async (historyLength, timeControlType, variation) => {
  try {
    const response = await fetch('https://grandhustler.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include user token for authentication
      },
      body: JSON.stringify({
        query: `
          query GetWinChart($historyLength: String!, $timeControlType: String!, $variation: String!) {
            getWinChart(historyLength: $historyLength, timeControlType: $timeControlType, variation: $variation){
              winPercentage
              drawPercentage
              lossPercentage
            }
          }
        `,
        variables: { historyLength, timeControlType, variation },
      }),
    });


    const result = await response.json();
    if (result.data) {
      const { winPercentage, lossPercentage, drawPercentage } = result.data.getWinChart;
      setChartData({
        win: Math.round(winPercentage), // Rounding to nearest integer
        loss: Math.round(lossPercentage),
        draw: Math.round(drawPercentage),
      });
    } else {
      console.error("Error fetching data:", result.errors);
    }
  } catch (error) {
    console.error("Fetch Error:", error);
  }
};
  return (
    <div>
      <Header onToggleSidebar={toggleSidebar} /> {/* Pass the toggleSidebar function to Header */}
      <div className="amaqao">
        <Sidebar
          isCollapsed={isSidebarCollapsed} // Pass the collapsed state to Sidebar
          onOpenCreateGame={handleOpenCreateGame}
          onToggleMessageTab={handleToggleMessageTab}
        />
        <div className="list-and-btslp-margin" style={{ marginLeft: isSidebarCollapsed ? '7.5rem' : '' }}>
          <div className="list-and-btslp">

            <div className='p-1 w-inherit'>
                <div className='p-1'>
                  <div className='medium-title'>franklucas's Elo</div>
                  {user ? (
                    <DailyComparisonChart user={user} />
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <div style={{ display: 'flex' }}>
                      <SkeletonLoaderWrapper height="calc(230px - 1rem)"/>
                      <SkeletonLoaderWrapper height="calc(230px - 1rem)"/>
                      </div>
                      <div>
                        <SkeletonLoaderWrapper height="calc(115px - 1rem)"/>
                        <SkeletonLoaderWrapper height="calc(115px - 1rem)"/>
                      </div>
                    </div>
                  )}
              </div>
              <AdBanner />
              <div className='media-group'>
                <div className='medium-title'>
                  <span>Your Overview</span>
                  <div style={{ display: 'flex', fontSize: '13px', marginBottom: '1rem', alignItems: 'center' }}>
                    <Dropdown
                      style={{ border: '2px solid #2b2b2b', padding: '4px 8px' }}
                      options={["Last 7 days", "Last 4 Weeks", "All Time"]}
                      defaultOption="Last 7 days"
                      onChange={handleWinHistoryLengthChange}
                      overview={true}
                    />
                    <span style={{ fontSize: '12px', color: '#999', margin: '0 0.5rem'}}>compared to</span>
                    <Dropdown
                      style={{ border: '2px solid #2b2b2b', padding: '4px 8px' }}
                      options={["30:00", "10:00", "5:00", "3:00", "1:00"]}
                      defaultOption="30:00"
                      onChange={handleWinTimeControlTypeChange}
                      overview={true}
                    />
                    <div style={{ marginLeft: '4px'}}>
                      <Dropdown
                        style={{ border: '2px solid #2b2b2b', padding: '4px 8px' }}
                        options={["Standard", "KOTH"]}
                        defaultOption="Standard"
                        onChange={handleVariationTypeChange}
                        overview={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='group-content'>
                <FinancialDonutChart
                  winPercentage={chartData.win}
                  lossPercentage={chartData.loss}
                  drawPercentage={chartData.draw}
                />
                <GameScroll />
                </div>
              </div>
              <div className='media-group'>
                <div className='medium-title'>Media</div>
                <div style={{ display: 'flex' }}>
                  <Matches />
                  <TvPlayer />
                </div>
              </div>
            <div className="media-group">
              <div className='medium-title'>Winners of the Week</div>
              <Ticker />
            </div>
            </div>
          </div>
          <OwnershipFooter />
          <Footer />
        </div>
      </div>
      <MessageTab isMessageTabOpen={isMessageTabOpen} onClose={handleToggleMessageTab} />
      <Modal show={showModal} onClose={handleCloseModal} onSubmit={handleSubmitModal} />
      {showNotification && <NotificationMessage title={notificationTitle} message={notificationMessage} />}
    </div>
  );
};

export default MainLayout;
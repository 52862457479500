import React from "react";

const SkeletonLoader = ({ height }) => (
  <div
    style={{
      height: height || "100px",
      maxWidth: '850px',
      flex: 1,
      background: "linear-gradient(90deg, #1e1e1e, #2a2a2a, #1e1e1e)",
      borderRadius: "1rem",
      margin: '1rem',
      animation: "shimmer 1.5s infinite linear",
      backgroundSize: "200% 100%",
    }}
  />
);

const skeletonStyles = `
  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
`;

const SkeletonLoaderWrapper = ({ height}) => {
  return (
    <>
      <style>{skeletonStyles}</style>
      <SkeletonLoader height={height} />
    </>
  );
};

export default SkeletonLoaderWrapper;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NameForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
  });
  const navigate = useNavigate();

  const validateName = (name) => name.trim().length >= 1;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!firstName || !validateName(firstName)) {
      newErrors.firstName = 'First name is required';
    }

    if (!lastName || !validateName(lastName)) {
      newErrors.lastName = 'Last name is required';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await fetch('https://yourapi.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `
              mutation {
                updateUserName(
                  firstName: "${firstName}",
                  lastName: "${lastName}"
                ) {
                  id
                  firstName
                  lastName
                }
              }
            `,
          }),
        });

        const result = await response.json();
        if (result.data && result.data.updateUserName) {
          navigate('/some-other-page'); // Navigate to another page after success
        } else {
          setErrors({ ...errors, general: 'An error occurred while updating your name' });
        }
      } catch (error) {
        console.error('Error during name update:', error);
        setErrors({ ...errors, general: 'An error occurred during name update' });
      }
    }
  };

  return (
    <div className="stack x-stretch y-center gap-large padding-none direction-vertical padding-left-auto padding-top-auto padding-bottom-auto padding-right-auto">
      <form
        data-test-form-valid="true"
        method="post"
        id="nameForm"
        className="samri-3jiw3t"
        onSubmit={handleSubmit}
      >
        <div className="input-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
          />
          {errors.firstName && <div className="error">{errors.firstName}</div>}
        </div>
        
        <div className="input-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
          />
          {errors.lastName && <div className="error">{errors.lastName}</div>}
        </div>

        {errors.general && <div className="error">{errors.general}</div>}
        
        <button
          className="button variant-success size-xl align-center min-width"
          type="submit"
          data-analytics="button-submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default NameForm;

import React, { useState, useRef, useEffect } from 'react';
import { gql } from '@apollo/client';
import TournamentForm from './TournamentForm';
import TournamentConfirmation from './ConfirmTourneyCreation';
import PlayVS from './FriendlyGameForm';

const GET_MUTUAL_FRIENDS = gql`
  query GetMutualFriends {
    getMutualFriends {
      id
      username
      profilePic
    }
  }
`;

const fetchWithToken = async (query) => {
  const token = localStorage.getItem('token');
  const response = await fetch('https://grandhustler.com/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ query }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const result = await response.json();
  
  return result;
};

const Modal = ({ show, onClose, onSubmit }) => {
  const [activeSection, setActiveSection] = useState('friend');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    tourneyName: '',
    hour: '',
    minute: '',
    wager: ''
  });
  const formRef = useRef(null);
  const [mutualFriends, setMutualFriends] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await fetchWithToken(GET_MUTUAL_FRIENDS.loc.source.body);
        setMutualFriends(result.data.getMutualFriends);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (!show) return null;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setSelectedFriend(null);
    if (section !== 'group') {
      setFormData({
        tourneyName: '',
        hour: '',
        minute: '',
        wager: ''
      });
    }
  };

  const goBack = () => {
    setActiveSection('friend');
    setSelectedFriend(null);
  }
  const handleFormDataChange = (data) => {
    setFormData(data);
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleCustomSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  const handleFriendClick = (friend) => {
    setSelectedFriend(friend);
  };

  return (
    <div className="create-game-shell">
      <div id="blur" className="sg-modal-blur"></div>
      <div className="sg-modal">
        <header className="header" style={{ paddingLeft: selectedFriend || activeSection === 'group' ? 0 : '1rem' }}>
          <div>
            <button type="button" className="previous" style={{ paddingLeft: '0' }} onClick={() => goBack()}>
            {selectedFriend || (activeSection === 'group') ? (
              <svg
              fill="#FFFFFF"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              enableBackground="new 0 0 100 100"
              stroke="#FFFFFF"
              style={{ width: '15px' }}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <path d="M33.934,54.458l30.822,27.938c0.383,0.348,0.864,0.519,1.344,0.519c0.545,0,1.087-0.222,1.482-0.657 c0.741-0.818,0.68-2.083-0.139-2.824L37.801,52.564L64.67,22.921c0.742-0.818,0.68-2.083-0.139-2.824 c-0.817-0.742-2.082-0.679-2.824,0.139L33.768,51.059c-0.439,0.485-0.59,1.126-0.475,1.723 C33.234,53.39,33.446,54.017,33.934,54.458z"></path>
                </g>
              </g>
            </svg>
            )
            : 
            ''
            }
              <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
                <path fillRule="evenodd" clipRule="evenodd" d="M45.71 13.349v.024c0 4.316-2.076 8.146-5.32 10.57H24.61l-.034-.024a13.19 13.19 0 0 1-5.286-10.57c0-7.296 5.914-13.21 13.21-13.21 7.296 0 13.21 5.914 13.21 13.21Zm4.806 22.844H62.23v-3.19a5.31 5.31 0 0 0-5.3-5.31H10.14a5.624 5.624 0 0 1-5.24-5.608v-.014a4.914 4.914 0 0 1 0-.522v.012a5.546 5.546 0 0 1 5.51-5.11h3.85a17.489 17.489 0 0 1-.26-2.88v-.01H7.06A7.07 7.07 0 0 0 0 20.63v37.1a6.14 6.14 0 0 0 6.13 6.13h50.79a5.31 5.31 0 0 0 5.31-5.31v-3.19H50.5c-.092.004-.2.006-.31.006-5.296 0-9.59-4.294-9.59-9.59s4.294-9.59 9.59-9.59c.108 0 .218.002.326.006Zm4.733-22.63v2.89h-4.516a18.36 18.36 0 0 0 .267-2.89h4.25Zm-4.516 2.89-.02.11.017-.11h.003ZM8.66 21.983c0-.98.792-1.774 1.77-1.78h4.91l.044.122a17.843 17.843 0 0 0 1.956 3.618h-6.91a1.77 1.77 0 0 1-1.77-1.77v-.19ZM64 39.943v11.67l-13.488-.002a5.84 5.84 0 0 1-6.094-5.834 5.84 5.84 0 0 1 6.082-5.834H64Zm-13.06 8.5a2.67 2.67 0 0 0 2.67-2.66v-.01a2.67 2.67 0 1 0-2.67 2.67Zm-1.26-28.24a18.188 18.188 0 0 1-1.998 3.74h-.002l-.038.058.04-.058H58v-3.74h-8.32Z"></path>
              </svg>
              <span>Create Game</span>
              <span>/</span>
              <span className="white">{selectedFriend ? selectedFriend.username : 'Swiss Tournaments'}</span>
            </button>
          </div>
          <button type="button" className="x-button" onClick={onClose}>
            <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
              <path d="m54.827 16.187-7.014-7.014L32 24.987 16.187 9.173l-7.014 7.014L24.987 32 9.173 47.813l7.014 7.014L32 39.013l15.813 15.814 7.014-7.014L39.013 32l15.814-15.813Z"></path>
            </svg>
          </button>
        </header>
        {selectedFriend || (activeSection === 'group') ? (
          ''
        ) : <div className="choices-container">
              <div 
                className={`index-cont friend ${activeSection === 'friend' ? 'active' : ''}`}
                onClick={() => handleSectionClick('friend')}
                style={{ justifyContent: 'center' }}
              >
                <span className="index-m c-w">Friends</span>
              </div>
              <div 
                className={`index-cont group ${activeSection === 'group' ? 'active' : ''}`}
                onClick={() => handleSectionClick('group')}
              >
                <div className="cursor">
                  <span className="index-m c-b">Tournament</span>
                </div>
              </div>
            </div>
      }

        <div className="wrap">
          <div className="shell-content">
            <div className="modal-content">
              <div className="cg">
                {selectedFriend ? (
                  <PlayVS
                    username={selectedFriend.username}
                    mutual={selectedFriend}
                    onSubmit={onSubmit} 
                    onClose={onClose}
                />
                ) : activeSection === 'friend' ? (
                  <div className="cg-friends">
                    {mutualFriends.map((friend, index) => (
                      <div key={friend.id} className={`cg-container ${index % 2 === 0 ? 'odd' : ''}`} onClick={() => handleFriendClick(friend)} >
                        <div className="ou-padding">
                          <div className="ou-child">
                            <div className="x9fdf">
                              <div className="f-pic-shell">
                                <div className="rspsm">
                                  <div className="ppic" style={{ height: '44px', width: '44px' }} tabIndex="0">
                                    <img
                                      alt={`${friend.username}'s profile picture`}
                                      className="rs-image"
                                      crossOrigin="anonymous"
                                      draggable="false"
                                      src={friend.profilePic}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="x9f-username">
                                <span className="username">{friend.username}</span>
                              </div>
                              <div className="poke-container">
                                <div className="pc-margin">
                                  <button className="poke">
                                    <div className="poke-txt">Select</div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <TournamentForm 
                    ref={formRef}
                    onDataChange={handleFormDataChange}
                    onCustomSubmit={handleCustomSubmit}
                    formData={formData}
                  />
                )}
              </div>
              {showConfirmation && (
                <TournamentConfirmation
                  onClose={handleCloseConfirmation}
                  formData={formData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
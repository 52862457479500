import { useState, useEffect } from 'react';

const GET_USERS = `
  query GetUsers {
    getUsers {
      id
      email
      username
      profilePic
      balance
    }
  }
`;

export function useUsers() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const authToken = localStorage.getItem('token');

      if (!authToken) {
        setError('No token found');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`, // Include the authorization token
        },
          body: JSON.stringify({ query: GET_USERS }),
        });

        const result = await response.json();

        if (response.ok) {
          setUsers(result.data.getUsers);
        } else {
          setError(result.errors || 'Error fetching users');
        }
      } catch (err) {
        setError('Error fetching data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { loading, users, error };
}

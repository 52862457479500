import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Dropdown from "./HomeDropdowns";
import SkeletonLoaderWrapper from "./SkeletonLoaderWrapper";

// Registering required Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DailyComparisonChart = ({ user }) => {
  const formattedBalance = new Intl.NumberFormat('en-US').format(user.balance * 10000);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeType, setTimeType] = useState("Classical");
  const [eloDataWeek, setEloDataWeek] = useState(null); // To hold fetched Elo data for Past Week
  const [eloDataMonth, setEloDataMonth] = useState(null); // To hold fetched Elo data for Past Month
  const [lastMonthElo, setLastMonthElo] = useState(null); // To hold fetched Elo data for Past Month
  const [lastWeekElo, setLastWeekElo] = useState(null);
  const [currentElo, setCurrentElo] = useState(null);

  const [loading, setLoading] = useState(true); 
  // Handler for Time Type change
  const handleTimeTypeChange = (selected) => {
    setTimeType(selected); // Update state
    fetchEloComparisonData(selected); // Trigger data fetch for both history lengths
  };

  // Fetch Elo comparison data for both Past Week and Past Month
  const fetchEloComparisonData = async (timeType) => {
    try {
      // Fetch data for Past Week
      const responseWeek = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include user token for authentication
        },
        body: JSON.stringify({
          query: `
            query GetEloComparison($timeType: String!, $historyLength: String!) {
              getEloComparison(timeType: $timeType, historyLength: $historyLength) {
                currentElo
                eloComparisonData
              }
            }
          `,
          variables: { timeType, historyLength: "Past Week" },
        }),
      });

      const resultWeek = await responseWeek.json();
      console.log('last week elo', resultWeek.data.getEloComparison.eloComparisonData[6])
      if (resultWeek.data) {
        setCurrentElo(resultWeek.data.getEloComparison.currentElo)
        setEloDataWeek(resultWeek.data.getEloComparison.eloComparisonData);
        setLastWeekElo(resultWeek.data.getEloComparison.eloComparisonData[6])
      } else {
        console.error("Error fetching Past Week Elo data:", resultWeek.errors);
      }

      // Fetch data for Past Month
      const responseMonth = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include user token for authentication
        },
        body: JSON.stringify({
          query: `
            query GetEloComparison($timeType: String!, $historyLength: String!) {
              getEloComparison(timeType: $timeType, historyLength: $historyLength) {
                currentElo
                eloComparisonData
              }
            }
          `,
          variables: { timeType, historyLength: "Past Month" },
        }),
      });

      const resultMonth = await responseMonth.json();
      if (resultMonth.data) {
        console.log('RESULT MONTH', resultMonth.data.getEloComparison.eloComparisonData[3]);
        setLastMonthElo(resultWeek.data.getEloComparison.eloComparisonData[3]);
        setEloDataMonth(resultMonth.data.getEloComparison.eloComparisonData);
      } else {
        console.error("Error fetching Past Month Elo data:", resultMonth.errors);
      }
    } catch (error) {
      console.error("Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEloComparisonData(timeType);
  }, [timeType]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  // Get the current hour and minute
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  const formattedTime = currentTime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });

  const renderEloComparison = (data, label) => {
    const chartData = {
      labels: Array.from({ length: data.length }, (_, index) => `${label} Day ${index + 1}`),
      datasets: [
        {
          data: data,
          fill: false,
          borderColor: 'rgb(172, 255, 195)',
          borderWidth: 1, // Set the line thickness to 1 (thinner line)
          tension: 0.1,
          pointRadius: 0,  // Remove points (rings) from the line
        },
      ],
    };

    return (
      <div style={{ width: '100%' }}>
        <Line
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false, // Disable the legend
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => `${tooltipItem.raw}`,
                },
              },
            },
            scales: {
              x: {
                display: false, // Hide x-axis
              },
              y: {
                display: false, // Hide y-axis
              },
            },
          }}
          style={{ maxHeight: '140px' }}
        />
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', marginTop: '2.5rem' }}>
    {!loading ? (
      <div style={{ flex: '1', paddingRight: '20px', maxWidth: '850px' }}>
        <div className="chartControls">
          <div className="timeControl">
            <Dropdown
              options={["Classical", "Blitz", "Bullet", "KOTH"]}
              defaultOption="Classical"
              onChange={handleTimeTypeChange}
              overview={false}
            />
            <div className="timeControlResult">{currentElo}</div>
          </div>
          <div className="timeControl">
            <button
                className="dropdown-button flex ai-c"
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  border: "none",
                  padding: "initial",
                }}
              >
              <div>Last Week Elo</div>
            </button>
            <div className="timeControlResult">{lastWeekElo}</div>
          </div>
          <div className="historyLength">
            <button
                className="dropdown-button flex ai-c"
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  border: "none",
                  padding: "initial",
                }}
              >
              <div>Past Week</div>
            </button>
            <div className="historyLengthResult">
                <svg
                  fill="rgb(172, 255, 195)"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  style={{ marginTop: "5px", marginRight: "4px" }}
                >
                  <path d="M21,21H3L12,3Z"></path>
                </svg>
                {currentElo - lastWeekElo}
            </div>
          </div>
        </div>

        <div className="timeNow">
          Updated {formattedTime}
        </div>

        <div style={styles.chartContainer}>
          {/* Elo comparison chart for Past Week */}
          {renderEloComparison(eloDataWeek, "Past Week")}
        </div>
      </div>
       ) : (
        <SkeletonLoaderWrapper height="calc(230px - 1rem)" />
       )}

      {user && !loading ? (
      <div style={{ flex: '1', paddingRight: '20px', maxWidth: '850px' }}>
        <div className="chartControls">
          <div className="timeControl">
            <button
                className="dropdown-button flex ai-c"
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  border: "none",
                  padding: "initial",
                }}
              >
              <div>Last Month Elo</div>
            </button>
            <div className="timeControlResult">{lastMonthElo}</div>
          </div>
          <div className="historyLength">
            <button
                className="dropdown-button flex ai-c"
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  border: "none",
                  padding: "initial",
                }}
              >
              <div>Past Month</div>
            </button>
            <div className="historyLengthResult">
                <svg
                  fill="rgb(172, 255, 195)"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  style={{ marginTop: "5px", marginRight: "4px" }}
                >
                  <path d="M21,21H3L12,3Z"></path>
                </svg>
                {currentElo - lastMonthElo}
            </div>
          </div>
        </div>

        <div className="timeNow">
          Updated {formattedTime}
        </div>

        <div style={styles.chartContainer}>
          {/* Elo comparison chart for Past Month */}
          {renderEloComparison(eloDataMonth, "Past Month")}
        </div>
      </div>
      ) : (
        <SkeletonLoaderWrapper height="calc(230px - 1rem)" />
      )}

      <div className="balance-section">
        {user && !loading ? (
            <div className="overall-balance">
            <div className=" balance-section-header flex">
              <div>GH balance</div>
              <div className="home-withdrawButton">Withdraw</div>
            </div>
            <div className="home-balance">
              ${formattedBalance}
            </div>
            <div className="home-balance-message">Must play before withdrawing</div>
          </div>
          ) : (
            <SkeletonLoaderWrapper height="calc(115px - 1rem)"/>
          )}

      {user && !loading ? (
         <div className="withdrawable-balance">
           <div className="balance-section-header flex">
             <div>Withdrawable balance</div>
             <div className="home-withdrawButton">Withdraw</div>
           </div>
           <div className="home-balance">
             ${formattedBalance}
           </div>
           <div className="home-balance-message">Expected Jan 10</div>
         </div>
         ) : (
          <SkeletonLoaderWrapper height="calc(115px - 1rem)" />
        )}
      </div>
    </div>
  );
};

const styles = {
  chartContainer: {
    position: "relative",
    width: "100%",       
    maxWidth: "none",          // Set maxHeight to 140px
    height: "140px",       // Ensure the height is set to 140px
    borderTop: "0",
  },
};

export default DailyComparisonChart;

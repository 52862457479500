import React, { useState, useRef } from 'react';
import { Chess } from 'chess.js';
import Chessboard from 'chessboardjsx';
import AfterGameModal from './AfterGameModal';
import TournamentOverModal from './TournamentOverModal';
import { useWebSocket } from '../contexts/WebSocketContext';
import '../css/ingame.css';

const ChessboardComponent = ({ flip, roomId, tournament, gameRoomId, variation, onMoveMade, setWhiteCapturedPieces, setBlackCapturedPieces }) => {
  const chessRef = useRef(new Chess());
  const [position, setPosition] = useState('start');
  const [showAfterGameModal, setShowAfterGameModal] = useState(false); // Renamed for clarity
  const [showTournamentOverModal, setShowTournamentOverModal] = useState(false); 
  const [standings, setStandings] = useState([]);
  const [winner, setWinner] = useState(null);
  const [modalData, setModalData] = useState({
    title: '',
    subtitle: { text: '', link: '' },
    playerData: [],
  });

  // Highlight squares
  const [sourceSquare, setSourceSquare] = useState(null);
  const [targetSquare, setTargetSquare] = useState(null);

  const moveSound = new Audio('https://images.chesscomfiles.com/chess-themes/sounds/_MP3_/default/move-self.mp3');
  const captureSound = new Audio('https://images.chesscomfiles.com/chess-themes/sounds/_MP3_/default/capture.mp3');
  const castleSound = new Audio('https://images.chesscomfiles.com/chess-themes/sounds/_MP3_/default/castle.mp3');

  const { socket } = useWebSocket();
  const idToSend = tournament ? gameRoomId : roomId;

  React.useEffect(() => {
    if (!socket) return;

    if (idToSend) {
      socket.emit('join_game_room', idToSend);
    
      socket.on('move_history', (moveHistory) => {
          if (!chessRef.current) {
            chessRef.current = new Chess();
          }
    
          // Apply the move history to the chess instance
          moveHistory.forEach(move => chessRef.current.move(move));
    
        // Set the board position to reflect the last move in history
        setPosition(chessRef.current.fen());
      });
    }

    const handleMove = (data) => {
      if (data.move) {
        console.log('incoming data', data);
    
        const move = chessRef.current.move(data.move);
  
        setSourceSquare(data.move.from);
        setTargetSquare(data.move.to);
    
        setPosition(chessRef.current.fen());
    
        if (move.captured) {
          captureSound.play();
    
          if (move.color === 'w') {
            setBlackCapturedPieces((prevPieces) => [...prevPieces, move.captured]);
          } 
          else {
            setWhiteCapturedPieces((prevPieces) => [...prevPieces, move.captured]);
          }
        }
    
        else if (move.san.includes('O-O') || move.san.includes('O-O-O')) {
          castleSound.play();
        } 
        else {
          moveSound.play();
        }
      }
  
      if (data.status !== 'ongoing') {
        let title, subtitle;
        switch (data.status.result) {
          case 'checkmate':
            title = 'Checkmate!';
            subtitle = {
              text: `${data.status.winner.charAt(0).toUpperCase() + data.status.winner.slice(1)} has won the game`,
              link: '#'
            };
            break;
      
          case 'king_of_the_hill':
            title = 'King of the Hill!';
            subtitle = {
              text: `${data.status.winner.charAt(0).toUpperCase() + data.status.winner.slice(1)} has won by reaching the center squares`,
              link: '#'
            };
            break;
      
          default:
            title = 'Game Over';
            subtitle = { text: 'The game is drawn', link: '#' };
        }
        setModalData({
          title,
          subtitle,
          playerData: [
            { username: data.player1, picture: 'https://www.chess.com/bundles/web/images/user-image.007dad08.svg', color: 'white' },
            { username: data.player2, picture: 'https://www.chess.com/bundles/web/images/user-image.007dad08.svg', color: 'black' }
          ]
        });
        setShowAfterGameModal(true);
      }
    };

    const handleGameOverDueToAbandonment = async () => {
      const player = chessRef.current.turn() === 'w' ? 'white' : 'black'
      const timeoutWinner = player === 'white' ? 'white' : 'black';

      setModalData({
        title: 'Game Abandoned',
        subtitle: { text: `Game ended due to abandonment by player ${player}. ${tournament ? `Winner is ${timeoutWinner}` : "Draw"}`, link: '#' },
        playerData: [] 
      });
      
      setShowAfterGameModal(true);

      const token = localStorage.getItem('token');
      const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            mutation EndGame($roomId: ID!, $finalStatus: FinalStatusInput!) {
              endGame(roomId: $roomId, finalStatus: $finalStatus) {
                success
                message
                player1
                player2
              }
            }
          `,
          variables: {
            roomId,
            finalStatus: {
              result: 'abandonment',
              winner: tournament ? timeoutWinner : 'draw'
            }
          }
        })
      });
      
      if (tournament) {
        socket.emit('tournament_game_over', { roomId, gameId: gameRoomId, winningColor: timeoutWinner, isDraw: false  });
      }
    }

    const handleGameOverDueToTimeout = ({ player }) => {      
      setModalData({
        title: 'Game Over',
        subtitle: { text: `Game ended due to timeout for ${player}.`, link: '#' },
        playerData: [] 
      });
      setShowAfterGameModal(true);

      const timeoutWinner = player === 'white' ? 'white' : 'black';

      if (tournament) {
        socket.emit('tournament_game_over', { roomId, gameId: gameRoomId, winningColor: timeoutWinner, isDraw: false  });
      }
    };

    const handleTournamentComplete = (data) => {
      setShowAfterGameModal(false); 
      setWinner(data.winner);
      setStandings(data.standings);
      setShowTournamentOverModal(true); 
    };

    socket.on('receive_move', handleMove);
    socket.on('game_abandoned', handleGameOverDueToAbandonment);
    socket.on('game_over_due_to_timeout', handleGameOverDueToTimeout);
    socket.on('tournament_complete', handleTournamentComplete);
    
    return () => {
      socket.off('receive_move', handleMove);
      socket.on('game_abandoned', handleGameOverDueToAbandonment);
      socket.off('game_over_due_to_timeout', handleGameOverDueToTimeout);
      socket.off('tournament_complete', handleTournamentComplete);
    };
  }, [socket, idToSend]);

  const centerSquares = ['d4', 'd5', 'e4', 'e5'];

  const handleDrop =  async ({ sourceSquare, targetSquare, piece }) => {
    const currentPlayerColor = flip ? 'b' : 'w';
    const pieceColor = chessRef.current.get(sourceSquare)?.color;

    if (pieceColor !== currentPlayerColor) {
      console.log('You can only move your own pieces');
      return 'snapback';
    }

    const move = chessRef.current.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: 'q'
    });

    if (move === null) {
      console.log(`Invalid move: ${JSON.stringify({ from: sourceSquare, to: targetSquare, promotion: 'q' })}`);
      return 'snapback';
    }

    if (move.captured) {
      captureSound.play();
      console.log(`Captured piece: ${move.captured}`);

      if (move.color === 'w') {
        setBlackCapturedPieces((prevPieces) => [...prevPieces, move.captured]);
      } else {
        setWhiteCapturedPieces((prevPieces) => [...prevPieces, move.captured]);
      }
    } else if (move.san.includes('O-O') || move.san.includes('O-O-O')) {
      castleSound.play();
    } else {
      moveSound.play();
    }

    setSourceSquare(sourceSquare);
    setTargetSquare(targetSquare);

    //King of the Hill logic
    if (variation === 'King of the Hill' && piece.toLowerCase().endsWith('k') && centerSquares.includes(targetSquare)) {
      const winningColor = chessRef.current.turn() === 'w' ? 'black' : 'white';
      const title = `${winningColor.charAt(0).toUpperCase() + winningColor.slice(1)} wins by the king reaching the hill!`;
      const subtitle = { text: `${winningColor} king reached the center`, link: '#' };
      
      const token = localStorage.getItem('token');
      const response = await fetch('https://grandhustler.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            mutation EndGame($roomId: ID!, $finalStatus: FinalStatusInput!) {
              endGame(roomId: $roomId, finalStatus: $finalStatus) {
                success
                message
                player1
                player2
              }
            }
          `,
          variables: {
            roomId,
            finalStatus: {
              result: 'king reached the hill',
              winner: winningColor
            }
          }
        })
      });

      const text = await response.text(); 
      let player1, player2;
      try {
        const result = JSON.parse(text);       
        player1 = result.data.endGame.player1;
        player2 = result.data.endGame.player2;

        if (tournament) {
          const isDraw = winningColor === 'draw' ? true : false;
          socket.emit('tournament_game_over', { roomId, gameId: gameRoomId, winningColor, isDraw });
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }

      setModalData({
        title,
        subtitle,
        playerData: [
          { username: player1, picture: 'https://www.chess.com/bundles/web/images/user-image.007dad08.svg', color: 'white' },
          { username: player2, picture: 'https://www.chess.com/bundles/web/images/user-image.007dad08.svg', color: 'black' }
        ]
      });

      setShowAfterGameModal(true);
      
      setPosition(chessRef.current.fen());

      if (socket) {
        socket.emit('send_move', {
          roomId: idToSend,
          move: {
            from: sourceSquare,
            to: targetSquare,
            piece,
          },
          status: { result: 'king_of_the_hill', winner: winningColor }
        });
      }
      return;
    }

    let status = 'ongoing';
    if (chessRef.current.isGameOver()) {
      if (chessRef.current.isCheckmate()) {
        const winningColor = chessRef.current.turn() === 'w' ? 'black' : 'white';
        status = { result: 'checkmate', winner: winningColor };
      } else if (chessRef.current.isStalemate()) {
        status = { result: 'stalemate', winner: 'draw' };
      } else if (chessRef.current.isThreefoldRepetition()) {
        status = { result: 'threefold_repetition', winner: 'draw' };
      } else if (chessRef.current.isInsufficientMaterial()) {
        status = { result: 'insufficient_material', winner: 'draw' };
      } else if (chessRef.current.isSeventyFiveMoves()) {
        status = { result: 'seventy_five_moves', winner: 'draw' };
      } else if (chessRef.current.isFivefoldRepetition()) {
        status = { result: 'fivefold_repetition', winner: 'draw' };
      }
    }

    if (socket) {
      const idToSend = tournament ? gameRoomId : roomId;
    
      socket.emit('send_move', {
        roomId: idToSend,
        move: {
          from: sourceSquare,
          to: targetSquare,
          piece,
        },
        status
      });

      onMoveMade({
        move: {
          from: sourceSquare,
          to: targetSquare,
          piece,
        },
        status
      });
    }

    if (chessRef.current.isGameOver()) {
      let title, subtitle;
      if (chessRef.current.isCheckmate()) {
        const winningColor = chessRef.current.turn() === 'w' ? 'black' : 'white';
        title = 'Checkmate!';
        subtitle = { text: `${winningColor.charAt(0).toUpperCase() + winningColor.slice(1)} has won the game`, link: '#' };
      } else if (chessRef.current.isStalemate()) {
        title = 'Stalemate!';
        subtitle = { text: 'The game is drawn', link: '#' };
      } else if (chessRef.current.isThreefoldRepetition()) {
        title = 'Threefold Repitition!';
        subtitle = { text: 'The game is drawn', link: '#' };
      } else if (chessRef.current.isInsufficientMaterial()) {
        title = 'Insufficient Material!';
        subtitle = { text: 'The game is drawn', link: '#' };
      } else if (chessRef.current.isSeventyFiveMoves()) {
        title = '75 Move Rule!';
        subtitle = { text: 'The game is drawn', link: '#' };
      } else if (chessRef.current.isFivefoldRepetition()) {
        title = 'Fivefold Repitition!';
        subtitle = { text: 'The game is drawn', link: '#' };
      }

      
      if (title) {
        // Call the endgame mutation
        const token = localStorage.getItem('token');
        const response = await fetch('https://grandhustler.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            query: `
              mutation EndGame($roomId: ID!, $finalStatus: FinalStatusInput!) {
                endGame(roomId: $roomId, finalStatus: $finalStatus) {
                  success
                  message
                  player1
                  player2
                }
              }
            `,
            variables: {
              roomId,
              finalStatus: {
                result: status.result,
                winner: status.winner
              }
            }
          })
        });
  
        const text = await response.text(); 
        let player1, player2;
        try {
          const result = JSON.parse(text);  // Parse the JSON

          player1 = result.data.endGame.player1;
          player2 = result.data.endGame.player2;

          if (tournament) {
            const isDraw = status.winner === 'draw' ? true : false;
            socket.emit('tournament_game_over', { roomId, gameId: gameRoomId, winningColor: status.winner, isDraw });
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
  
        setModalData({
          title,
          subtitle,
          playerData: [
            { username: player1 , picture: 'https://www.chess.com/bundles/web/images/user-image.007dad08.svg', color: 'white' },
            { username: player2 , picture: 'https://www.chess.com/bundles/web/images/user-image.007dad08.svg', color: 'black' }
          ]
        });
        setShowAfterGameModal(true);
      }
    }

    setPosition(chessRef.current.fen());
  };

  const handleCloseAfterGameModal = () => {
    setShowAfterGameModal(false);
  };

  const handleCloseTournamentOverModal = () => {
    setShowTournamentOverModal(false);
  };


  const defaultSquareStyles = {};

  const pieceTheme = {
    wP: '/public/assets/bP.png', // White Pawn
    wR: 'my-app/client/public/assets/bP.png', // White Rook
    wN: '/public/assets/bP.svg', // White Knight
    wB: '/public/assets/bP.svg', // White Bishop
    wQ: '/public/assets/bP.svg', // White Queen
    wK: '/public/assets/bP.svg', // White King
    bP: '/assets/bP.png', // Local Black Pawn
    bR: '/public/assets/bP.svg', // Black Rook
    bN: '/public/assets/bP.svg', // Black Knight
    bB: '/public/assets/bP.svg', // Black Bishop
    bQ: '/public/assets/bP.svg', // Black Queen
    bK: '/public/assets/bP.svg', // Black King
  };

  const colors = {
    light: '#F0E4D7',  // Light square color
    dark: '#4A6E4D',   // Dark square color
  };
  
  for (let rank = 1; rank <= 8; rank++) {
    for (let file of ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']) {
      const square = `${file}${rank}`;
      // Determine the color based on the square's coordinates
      if ((file.charCodeAt(0) - 'a'.charCodeAt(0) + rank) % 2 === 0) {
        defaultSquareStyles[square] = { backgroundColor: colors.light };
      } else {
        defaultSquareStyles[square] = { backgroundColor: colors.dark };
      }
    }
  }
  
  const getSquareStyles = () => {
    const styles = { ...defaultSquareStyles };
  
    // Highlight source and target squares
    if (sourceSquare) {
      styles[sourceSquare] = { backgroundColor: 'rgba(255, 255, 0, 0.5)' };
    }
    if (targetSquare) {
      styles[targetSquare] = { backgroundColor: 'rgba(255, 255, 0, 0.5)' };
    }
  
    return styles;
  };
  
  return (
    <div className='chessboard-edge'>
      <Chessboard
        id="chessboard"
        width={600}
        position={position}
        draggable={true}
        orientation={flip ? 'black' : 'white'}
        squareStyles={getSquareStyles()}
        onDrop={handleDrop}
        pieceTheme={pieceTheme}
      />
      {showAfterGameModal && (
        <AfterGameModal
          title={modalData.title}
          subtitle={modalData.subtitle}
          playerData={modalData.playerData}
          onClose={handleCloseAfterGameModal}
        />
      )}
      {showTournamentOverModal && (
        <TournamentOverModal 
          onClose={handleCloseTournamentOverModal} 
          winner={winner} 
          standings={standings} 
        />
      )}
    </div>
  );
};

export default ChessboardComponent;
import React, { useState } from 'react';

const PhysicalTournamentModal = ({
  isOpen,
  onClose,
  title,
  street,
  city,
  date,
  state,
  hour,
  minute,
  bio,
  type,
}) => {
  const [selectedCategory, setSelectedCategory] = useState('Address'); // Track the selected category

  if (!isOpen) return null; // Don't render the modal if it's not open

  const renderContent = () => {
    switch (selectedCategory) {
      case 'Address':
        return (
          <>
            <span className='categoryTop'>ADDRESS</span>
            <div className='categorySubject'>
              <span>{street},</span>
              <span> {city},</span>
              <span> {state}.</span>
            </div>
         </>
        );
      case 'Time':
        return (
          <>
          <span className='categoryTop'>TIME</span>
          <div className='categorySubject'> 
            <span>{date}</span>
            <span style={{ marginLeft: '1rem' }}>{`${hour}:${minute}`}</span>
          </div>
          </>
        );
      case 'Bio':
        return (
        <>
          <span className='categoryTop'>BIO</span>
          <span className='categorySubject'>{bio}</span>
        </>
        );
      default:
        return null;
    }
  };


  return (
    <div className="create-game-shell">
      <div id="blur" className="sg-modal-blur" style={{ backgroundColor: 'transparent'}}></div>
      <div className="sg-modal">
        <header className="header" style={{ padding: 0 }}>
              <div>
                <button type="button" className="previous">
                  <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
                    <path fillRule="evenodd" clipRule="evenodd" d="M45.71 13.349v.024c0 4.316-2.076 8.146-5.32 10.57H24.61l-.034-.024a13.19 13.19 0 0 1-5.286-10.57c0-7.296 5.914-13.21 13.21-13.21 7.296 0 13.21 5.914 13.21 13.21Zm4.806 22.844H62.23v-3.19a5.31 5.31 0 0 0-5.3-5.31H10.14a5.624 5.624 0 0 1-5.24-5.608v-.014a4.914 4.914 0 0 1 0-.522v.012a5.546 5.546 0 0 1 5.51-5.11h3.85a17.489 17.489 0 0 1-.26-2.88v-.01H7.06A7.07 7.07 0 0 0 0 20.63v37.1a6.14 6.14 0 0 0 6.13 6.13h50.79a5.31 5.31 0 0 0 5.31-5.31v-3.19H50.5c-.092.004-.2.006-.31.006-5.296 0-9.59-4.294-9.59-9.59s4.294-9.59 9.59-9.59c.108 0 .218.002.326.006Zm4.733-22.63v2.89h-4.516a18.36 18.36 0 0 0 .267-2.89h4.25Zm-4.516 2.89-.02.11.017-.11h.003ZM8.66 21.983c0-.98.792-1.774 1.77-1.78h4.91l.044.122a17.843 17.843 0 0 0 1.956 3.618h-6.91a1.77 1.77 0 0 1-1.77-1.77v-.19ZM64 39.943v11.67l-13.488-.002a5.84 5.84 0 0 1-6.094-5.834 5.84 5.84 0 0 1 6.082-5.834H64Zm-13.06 8.5a2.67 2.67 0 0 0 2.67-2.66v-.01a2.67 2.67 0 1 0-2.67 2.67Zm-1.26-28.24a18.188 18.188 0 0 1-1.998 3.74h-.002l-.038.058.04-.058H58v-3.74h-8.32Z"></path>
                  </svg>
                  <span>Physical Tournament</span>
                  <span>/</span>
                  <span className="white" style={{ maxWidth: '100px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{title ? title : ''}</span>
                </button>
              </div>
              <button type="button" className="x-button" onClick={onClose}>
                <svg fill="currentColor" viewBox="0 0 64 64" width="16px" className="svg-icon">
                  <path d="m54.827 16.187-7.014-7.014L32 24.987 16.187 9.173l-7.014 7.014L24.987 32 9.173 47.813l7.014 7.014L32 39.013l15.813 15.814 7.014-7.014L39.013 32l15.814-15.813Z"></path>
                </svg>
              </button>
            </header>
            <div style={{ padding: '0.5rem 1rem' }}>
          <div className="createGameTitle">More Info</div>
          <div className="moreInfoCategories">
            {['Address', 'Time', 'Bio'].map((category) => (
              <button
                key={category}
                className={`infoCategory ${
                  selectedCategory === category ? 'selected' : ''
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                  <span>{category}</span>
                  <span>1</span>
                </div>
              </button>
            ))}
          </div>
          <div className="infoContent">{renderContent()}</div>
        </div>
        </div>
      </div>
  );
};

export default PhysicalTournamentModal;
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PostPhysicalTournamentForm = () => {
  const [startDate, setStartDate] = useState(null);
  const [title, setTitle] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [bio, setBio] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title || !street || !city || !state || !startDate || !hour || !minute) {
      setError("All fields are required");
      return;
    }
    setError("");
    console.log(startDate.toISOString());
    try {
      const data = await post(title, street, city, state, startDate.toISOString(), hour, minute, bio);

      console.log("Tournament posted successfully:", data);
      
      setTitle("");
      setStreet("");
      setCity("");
      setState("");
      setStartDate(null);
      setHour("");
      setMinute("");
      setBio("");
    } catch (error) {
      console.error("Error posting tournament:", error);
      setError("Failed to post tournament. Please try again.");
    }
  };


  return (
    <form onSubmit={handleSubmit} className="casing">
      <div style={{ display: "flex", gap: '0.5rem' }}>
      <label className="stacked samri-1ww0eyq">
        <div className="oneid-email-strength-container"></div>
        <div className="input-wrap samri-1pryo3e">
          <div className="input-content samri-1pryo3e">
            <div className="before-icon samri-1pryo3e"></div>
            <div className="after-icon samri-1pryo3e"></div>
            <input
              autoComplete="on"
              id="physical-tournament-title"
              className="input spacing-expanded samri-1pryo3e"
              type="text"
              name="title"
              max="Infinity"
              data-test="physical-tournament-title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="input-button-wrap samri-1pryo3e"></div>
        </div>
        <span className="label-content samri-5ecfln">
          <div className="label-left-wrapper samri-1pryo3e">
            <span>Title</span>
            <div className="asterisk-wrapper samri-1pryo3e">
              <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
            </div>
          </div>
        </span>
        {error && !title && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
      </label>
      <label className="stacked samri-1ww0eyq">
        <div className="oneid-email-strength-container"></div>
        <div className="input-wrap samri-1pryo3e">
          <div className="input-content samri-1pryo3e">
            <div className="before-icon samri-1pryo3e"></div>
            <div className="after-icon samri-1pryo3e"></div>
            <input
              autoComplete="on"
              id="physical-tournament-street"
              className="input spacing-expanded samri-1pryo3e"
              type="text"
              name="Street"
              max="Infinity"
              data-test="physical-tournament-street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </div>
          <div className="input-button-wrap samri-1pryo3e"></div>
        </div>
        <span className="label-content samri-5ecfln">
          <div className="label-left-wrapper samri-1pryo3e">
            <span>Street Address</span>
            <div className="asterisk-wrapper samri-1pryo3e">
              <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
            </div>
          </div>
        </span>
        {error && !street && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
      </label>
      </div>
      <div style={{ display: "flex", gap: '0.5rem' }}>
      <label className="stacked samri-1ww0eyq" style={{ width: 'auto' }}>
        <div className="oneid-email-strength-container"></div>
        <div className="input-wrap samri-1pryo3e">
          <div className="input-content samri-1pryo3e">
            <div className="before-icon samri-1pryo3e"></div>
            <div className="after-icon samri-1pryo3e"></div>
            <input
              autoComplete="on"
              id="physical-tournament-city"
              className="input spacing-expanded samri-1pryo3e"
              type="text"
              name="city"
              max="Infinity"
              data-test="physical-tournament-city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="input-button-wrap samri-1pryo3e"></div>
        </div>
        <span className="label-content samri-5ecfln">
          <div className="label-left-wrapper samri-1pryo3e">
            <span>City</span>
            <div className="asterisk-wrapper samri-1pryo3e">
              <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
            </div>
          </div>
        </span>
        {error && !city && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
      </label>
        <label className="stacked samri-1ww0eyq" style={{ width: 'auto' }}>
          <div className="input-wrap samri-1pryo3e">
            <div className="input-content samri-1pryo3e">
              <div className="before-icon samri-1pryo3e"></div>
              <div className="after-icon samri-1pryo3e"></div>
              <select
                id="physical-tournament-state"
                className="input spacing-expanded samri-1pryo3e"
                name="state"
                data-test="physical-tournament-state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                style={{ padding: '0.5rem' }}
              >
                <option value="" disabled>Select State</option>
                {[
                  { label: "Alabama", value: "AL" },
                  { label: "Alaska", value: "AK" },
                  { label: "Arizona", value: "AZ" },
                  { label: "Arkansas", value: "AR" },
                  { label: "California", value: "CA" },
                  { label: "Colorado", value: "CO" },
                  { label: "Connecticut", value: "CT" },
                  { label: "Delaware", value: "DE" },
                  { label: "Florida", value: "FL" },
                  { label: "Georgia", value: "GA" },
                  { label: "Hawaii", value: "HI" },
                  { label: "Idaho", value: "ID" },
                  { label: "Illinois", value: "IL" },
                  { label: "Indiana", value: "IN" },
                  { label: "Iowa", value: "IA" },
                  { label: "Kansas", value: "KS" },
                  { label: "Kentucky", value: "KY" },
                  { label: "Louisiana", value: "LA" },
                  { label: "Maine", value: "ME" },
                  { label: "Maryland", value: "MD" },
                  { label: "Massachusetts", value: "MA" },
                  { label: "Michigan", value: "MI" },
                  { label: "Minnesota", value: "MN" },
                  { label: "Mississippi", value: "MS" },
                  { label: "Missouri", value: "MO" },
                  { label: "Montana", value: "MT" },
                  { label: "Nebraska", value: "NE" },
                  { label: "Nevada", value: "NV" },
                  { label: "New Hampshire", value: "NH" },
                  { label: "New Jersey", value: "NJ" },
                  { label: "New Mexico", value: "NM" },
                  { label: "New York", value: "NY" },
                  { label: "North Carolina", value: "NC" },
                  { label: "North Dakota", value: "ND" },
                  { label: "Ohio", value: "OH" },
                  { label: "Oklahoma", value: "OK" },
                  { label: "Oregon", value: "OR" },
                  { label: "Pennsylvania", value: "PA" },
                  { label: "Rhode Island", value: "RI" },
                  { label: "South Carolina", value: "SC" },
                  { label: "South Dakota", value: "SD" },
                  { label: "Tennessee", value: "TN" },
                  { label: "Texas", value: "TX" },
                  { label: "Utah", value: "UT" },
                  { label: "Vermont", value: "VT" },
                  { label: "Virginia", value: "VA" },
                  { label: "Washington", value: "WA" },
                  { label: "West Virginia", value: "WV" },
                  { label: "Wisconsin", value: "WI" },
                  { label: "Wyoming", value: "WY" },
                ].map((stateOption) => (
                  <option key={stateOption.value} value={stateOption.value}>
                    {stateOption.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-button-wrap samri-1pryo3e"></div>
          </div>
          <span className="label-content samri-5ecfln">
            <div className="label-left-wrapper samri-1pryo3e">
              <span>State</span>
              <div className="asterisk-wrapper samri-1pryo3e">
                <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
              </div>
            </div>
          </span>
          {error && !state && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
        </label> 
        <label className="stacked samri-1ww0eyq" style={{ width: '0px' }}>
            <div className="input-wrap samri-1pryo3e">
            <div className="input-content samri-1pryo3e">
                <div className="before-icon samri-1pryo3e"></div>
                <div className="after-icon samri-1pryo3e"></div>
                <DatePicker
                id="physical-tournament-date"
                className="input spacing-expanded samri-1pryo3e"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/dd/yyyy"
                />
            </div>
            <div className="input-button-wrap samri-1pryo3e"></div>
            </div>
            <span className="label-content samri-5ecfln">
            <div className="label-left-wrapper samri-1pryo3e">
                <span>Date</span>
                <div className="asterisk-wrapper samri-1pryo3e">
                <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
                </div>
            </div>
            </span>
            {error && !startDate && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
        </label>
      <label className="stacked samri-1ww0eyq" style={{ width: 'auto' }}>
        <div className="oneid-email-strength-container"></div>
          <div className="input-wrap samri-1pryo3e">
            <div className="input-content samri-1pryo3e">
              <div className="before-icon samri-1pryo3e"></div>
              <div className="after-icon samri-1pryo3e"></div>
              <select
                id="physical-tournament-hour"
                className="input spacing-expanded samri-1pryo3e"
                name="state"
                data-test="physical-tournament-hour"
                value={hour}
                onChange={(e) => setHour(e.target.value)}
                style={{ padding: '0.5rem' }}
              >
                <option value="" disabled>Hour</option>
                {[
                  { label: "0", value: "0" },
                  { label: "1", value: "1" },
                  { label: "2", value: "2" },
                  { label: "3", value: "3" },
                  { label: "4", value: "4" },
                  { label: "5", value: "5" },
                  { label: "6", value: "6" },
                  { label: "7", value: "7" },
                  { label: "8", value: "8" },
                  { label: "9", value: "9" },
                  { label: "10", value: "10" },
                  { label: "11", value: "11" },
                  { label: "12", value: "12" },
                  { label: "13", value: "13" },
                  { label: "14", value: "14" },
                  { label: "15", value: "15" },
                  { label: "16", value: "16" },
                  { label: "17", value: "17" },
                  { label: "18", value: "18" },
                  { label: "19", value: "19" },
                  { label: "20", value: "20" },
                  { label: "21", value: "21" },
                  { label: "22", value: "22" },
                  { label: "23", value: "23" },
                ].map((hourOption) => (
                  <option key={hourOption.value} value={hourOption.value}>
                    {hourOption.label}
                  </option>
                ))}
              </select>
             <select
                id="physical-tournament-hour"
                className="input spacing-expanded samri-1pryo3e"
                name="state"
                data-test="physical-tournament-hour"
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
                style={{ padding: '0.5rem' }}
              >
                <option value="" disabled>Minute</option>
                {[
                  { label: "00", value: "00" },
                  { label: "01", value: "01" },
                  { label: "02", value: "02" },
                  { label: "03", value: "03" },
                  { label: "04", value: "04" },
                  { label: "05", value: "05" },
                  { label: "06", value: "06" },
                  { label: "07", value: "07" },
                  { label: "08", value: "08" },
                  { label: "09", value: "09" },
                  { label: "10", value: "10" },
                  { label: "11", value: "11" },
                  { label: "12", value: "12" },
                  { label: "13", value: "13" },
                  { label: "14", value: "14" },
                  { label: "15", value: "15" },
                  { label: "16", value: "16" },
                  { label: "17", value: "17" },
                  { label: "18", value: "18" },
                  { label: "19", value: "19" },
                  { label: "20", value: "20" },
                  { label: "21", value: "21" },
                  { label: "22", value: "22" },
                  { label: "23", value: "23" },
                  { label: "24", value: "24" },
                  { label: "25", value: "25" },
                  { label: "26", value: "26" },
                  { label: "27", value: "27" },
                  { label: "28", value: "28" },
                  { label: "29", value: "29" },
                  { label: "30", value: "30" },
                  { label: "31", value: "31" },
                  { label: "32", value: "32" },
                  { label: "33", value: "33" },
                  { label: "34", value: "34" },
                  { label: "35", value: "35" },
                  { label: "36", value: "36" },
                  { label: "37", value: "37" },
                  { label: "38", value: "38" },
                  { label: "39", value: "39" },
                  { label: "40", value: "40" },
                  { label: "41", value: "41" },
                  { label: "42", value: "42" },
                  { label: "43", value: "43" },
                  { label: "44", value: "44" },
                  { label: "45", value: "45" },
                  { label: "46", value: "46" },
                  { label: "47", value: "47" },
                  { label: "48", value: "48" },
                  { label: "49", value: "49" },
                  { label: "50", value: "50" },
                  { label: "51", value: "51" },
                  { label: "52", value: "52" },
                  { label: "53", value: "53" },
                  { label: "54", value: "54" },
                  { label: "55", value: "55" },
                  { label: "56", value: "56" },
                  { label: "57", value: "57" },
                  { label: "58", value: "58" },
                  { label: "59", value: "59" },
                ].map((minuteOption) => (
                  <option key={minuteOption.value} value={minuteOption.value}>
                    {minuteOption.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-button-wrap samri-1pryo3e"></div>
          </div>
          <span className="label-content samri-5ecfln">
            <div className="label-left-wrapper samri-1pryo3e">
              <span>Time</span>
              <div className="asterisk-wrapper samri-1pryo3e">
                <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
              </div>
            </div>
          </span>
          {error && !hour && !minute && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
        </label>
        </div>
        <div>
        <label className="stacked samri-1ww0eyq">
          <div className="oneid-email-strength-container"></div>
          <div className="input-wrap samri-1pryo3e">
            <div className="input-content samri-1pryo3e">
              <div className="before-icon samri-1pryo3e"></div>
              <div className="after-icon samri-1pryo3e"></div>
              <textarea
                autoComplete="on"
                id="physical-tournament-bio"
                className="input spacing-expanded samri-1pryo3e"
                name="bio"
                data-test="physical-tournament-bio"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                style={{ height: '80px', whiteSpace: 'pre-wrap', padding: '0.5rem' }} // 'pre-wrap' preserves new lines and white spaces
              />
            </div>
            <div className="input-button-wrap samri-1pryo3e"></div>
          </div>
          <span className="label-content samri-5ecfln">
            <div className="label-left-wrapper samri-1pryo3e">
              <span>Bio</span>
              <div className="asterisk-wrapper samri-1pryo3e">
                <span className="asterisk weight-normal line-height-none align-left size-default text-size-default variant-negative with-icon-space samri-1myjzud">*</span>
              </div>
            </div>
          </span>
          {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
        </label>
        </div>
      <div className="w-100 flex fd-c">
        <button type="submit" className="post-physical-tournament">
            Post
        </button>
        </div>
      {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
    </form>
  );
};

export default PostPhysicalTournamentForm;

async function post(title, street, city, state, date, hour, minute, bio) {
    try {
        console.log('post hour', hour);
        console.log('post min', minute);
        const token = localStorage.getItem('token');
        // Ensure the token exists
        if (!token) {
            throw new Error('No token found in localStorage');
        }
    
        // Make the fetch request with the Authorization header
        const response = await fetch('https://grandhustler.com/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            },
            body: JSON.stringify({
                query: `
                    mutation CreatePhysicalTournament($title: String!, $street: String!, $city: String!, $state: String!, $date: String!, $hour: String!, $minute: String!, $bio: String $type: String!) {
                        createPhysicalTournament(title: $title, street: $street, city: $city, state: $state, date: $date, hour: $hour, minute: $minute, bio: $bio type: $type) {
                            id
                            title
                            street
                            city
                            state
                            date
                            hour
                            minute
                            bio
                            type
                        }
                    }
                `,
                variables: {
                    title: title,
                    street: street,
                    city: city,
                    state: state,
                    date: date,
                    hour: hour,
                    minute: minute,
                    bio,
                    type: 'local'
                }
            })
        });
    
        // Handle the response
        if (!response.ok) {
            const errorText = await response.text(); // Get error message from response
            throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
        }
    
        const data = await response.json();
        if (data.errors) {
            throw new Error(`GraphQL error: ${data.errors.map(err => err.message).join(', ')}`);
        }
    
        console.log('Created Physical Tournament:', data);
        return data;
    } catch (error) {
        console.error('Error creating physical tournament:', error);
    }
}
import React, { useState, useEffect, useRef } from "react";

const Dropdown = ({ options, defaultOption, onChange, overview }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(defaultOption);
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    setSelected(option);
    setIsOpen(false);
    if (onChange) onChange(option);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="dropdown-container"
      style={{ position: "relative", display: "inline-block" }}
      ref={dropdownRef}
    >
      <button
        className="dropdown-button flex ai-c"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          color: "#fff",
          cursor: "pointer",
          border: overview ? "2px solid #2b2b2b" : "none",
          padding: overview ? "4px 8px" : "initial",
        }}
      >
        <div>{selected}</div>
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
          style={{ marginLeft: "8px" }}
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </button>
      {isOpen && (
        <div
          className="dropdown-menu"
          style={{
            position: "absolute",
            top: "calc(100% + 8px)",
            left: "0",
            backgroundColor: "#333",
            color: "#fff",
            borderRadius: "4px",
            width: "150px",
            zIndex: 10,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxHeight: '400px',
            overflow: 'auto'
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-8px",
              left: "16px",
              width: "0",
              height: "0",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderBottom: "8px solid #333",
            }}
          ></div>
          {options.map((option, index) => (
            <div
              key={option}
              onClick={() => handleSelect(option)}
              style={{
                padding: "8px 12px",
                cursor: "pointer",
                backgroundColor: selected === option ? "#28a745" : "transparent",
                display: "flex",
                alignItems: "center",
                borderRadius:
                  index === 0
                    ? "4px 4px 0 0"
                    : index === options.length - 1
                    ? "0 0 4px 4px"
                    : "0",
              }}
            >
              {selected === option && (
                <svg
                  fill="#fff"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  style={{ marginRight: "8px" }}
                >
                  <path d="M21,21H3L12,3Z"></path>
                </svg>
              )}
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import '../css/loadingOverlay.css'

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const newSocket = io("wss://grandhustler.com", {
      path: "/socket.io/",
      transports: ["websocket"],
    });
    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, []);

  const joinUserRoom = (userId) => {
    if (socket) {
      socket.emit('join_user_room', userId);
      console.log(`Requested to join user room ${userId}`);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('new_invitation', (invitation) => {
        console.log('new invitation', invitation);
        setNotifications((prev) => prev + 1);
      });

      socket.on('send_invitation', (invitation) => {
        console.log('send_invitation', invitation);
      });

      socket.on('rejected', () => {
        console.log('Invitation rejected');
      });

      socket.on('game_accepted', (data) => {
        setShowOverlay(true); // Show the overlay
        setTimeout(() => {
          setShowOverlay(false);
          navigate(`/game/${data.roomId}`, {
            state: {
              roomId: data.roomId,
              flip: data.flip,
              color: data.color,
              time: data.time,
              variation: data.variation,
              player1Username: data.player1Username,
              player2Username: data.player2Username,
              player1Id: data.player1Id,
              player2Id: data.player2Id,
            },
          });
        }, 5000); // Navigate after 5 seconds
      });

      socket.on('join_game_tournament', (data) => {
        const startNextRoundMessage = document.querySelector('.start-next');
        if (startNextRoundMessage) {
          startNextRoundMessage.textContent = `starting next round...`;
        }
        setTimeout(() => {
          navigate(`/lobby/${data.roomId}`);
          setTimeout(() => {
            navigate(`/game/${data.gameRoomId}`, {
              state: {
                roomId: data.roomId,
                gameRoomId: data.gameRoomId,
                flip: data.flip,
                time: data.time,
                tournament: true,
                currentRound: data.currentRound,
                totalRounds: data.totalRounds,
                initialStandings: data.standings,
                variation: data.variation,
              },
            });
          }, 100);
        }, 5000);
      });

      return () => {
        socket.off('new_invitation');
        socket.off('send_invitation');
        socket.off('rejected');
        socket.off('game_accepted');
        socket.off('join_game_tournament');
      };
    }
  }, [socket, navigate]);

  return (
    <WebSocketContext.Provider value={{ socket, joinUserRoom, notifications }}>
      {children}

      {/* Overlay for loading */}
      {showOverlay && (
        <div className="loading-overlay">
          <div className="loading-content">
            <div className="loading-circle"></div>
            <p>Preparing for battle...</p>
          </div>
        </div>
      )}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

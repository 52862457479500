import React, { useState, forwardRef } from 'react';

const Dropdown = forwardRef(({ options, selected, setSelected, label, className }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.preventDefault(); // Prevent form submission if the button is inside a form
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, e) => {
    e.preventDefault(); // Prevent form submission if the option is inside a form
    setSelected(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdown" ref={ref}>
      <button
        className={`tourney-input ${className}`}
        onClick={toggleDropdown}
        type="button" // Ensure this does not submit the form
        style={{ color: className ? 'white' : '' }}
      >
        <span>{selected || label}</span>

        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
          style={{ marginLeft: "8px" }}
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </button>
      {isOpen && (
        <div
          className="dropdown-menu"
          style={{
            position: "relative",
            backgroundColor: "#333",
            color: "#fff",
            borderRadius: "4px",
            width: "100%",
            zIndex: 10,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            marginTop: '8px'
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-8px",
              left: "16px",
              width: "0",
              height: "0",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderBottom: "8px solid #333",
            }}
          ></div>
          {options.map((option, index) => (
            <div
              key={option}
              onClick={(e) => handleOptionClick(option, e)}
              style={{
                padding: "8px 12px",
                cursor: "pointer",
                backgroundColor: selected === option ? "#28a745" : "transparent",
                display: "flex",
                alignItems: "center",
                borderRadius:
                  index === 0
                    ? "4px 4px 0 0"
                    : index === options.length - 1
                    ? "0 0 4px 4px"
                    : "0",
              }}
            >
              {selected === option && (
                <svg
                  fill="#fff"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  style={{ marginRight: "8px" }}
                >
                  <path d="M21,21H3L12,3Z"></path>
                </svg>
              )}
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default Dropdown;
